<i18n>
{
  "no": {
    "youNeedToContinueWithTelenorID": "Fortsett med Telenor ID",
    "phoneNumber": "Mobilnummer",
    "phoneNumberPlaceholder": "Skriv inn ditt mobilnummer",
    "infoMessage": "Nummeret hjelper oss å bekrefte identiteten din",
    "badInput": "Ugyldig mobilnummer. Husk landkode hvis nummeret ikke er norsk.",
    "next": "Fortsett med Telenor ID",
    "whatIsTelenorID": "Hva er Telenor ID?",
    "genericError": "Noe gikk galt, prøv igjen senere.",
    "maintenance": {
      "header": "Vedlikehold",
      "text": "Innlogging vil være utilgjengelig mens vi jobber med vedlikehold på våre digitale tjenester. Vi beklager eventuelle ulemper dette måtte medføre"
    }
  },
  "en": {
    "youNeedToContinueWithTelenorID": "Continue with Telenor ID",
    "phoneNumber": "Mobile number",
    "phoneNumberPlaceholder": "Enter your mobile number",
    "infoMessage": "Your number helps us verify your identity",
    "badInput": "Invalid mobile number. Remember country code if the number is not Norwegian.",
    "next": "Continue with Telenor ID",
    "whatIsTelenorID": "What is Telenor ID?",
    "genericError": "Something went wrong. Wait a few moments and try again",
    "maintenance": {
      "header": "Maintenance",
      "text": "Login will be unavailable while we work on maintenance of our digital services. We apologize for any inconvenience this may cause"
    }
  }
}
</i18n>
<template>
  <div class="container" v-if="this.environment.maintenance !== 'true'">
    <form-wrapper>
      <heading size="m" class="heading"> {{$i18n.t('youNeedToContinueWithTelenorID')}} </heading>

      <div class="error" v-if="error">
        <span>{{ $t(error) }}</span>
      </div>

      <div class="input-fields">
        <form method="post" action="/account/login" ref="form">
          <InputText
            :placeholder="$i18n.t('phoneNumberPlaceholder')"
            :info-message="$i18n.t('infoMessage')"
            autocomplete="tel-national"
            inputmode="tel"
            id="txtLoginHint"
            v-model="loginHint"
            :invalid-error="$t('badInput')"
            :validation="validation"
            validate-on-blur
            name="loginHint"
            :text="$t('phoneNumber')"
            :showLabel="false"
          />
          <t-button class="button login-button button-primary iconbutton"
                    compType="submit-button"
                    :value="$i18n.t('next')"
                    id="btnGo"
          />
          <div class="bottom-link">
            <a :href="tndLink" class="link">{{$i18n.t('whatIsTelenorID')}}</a>
          </div>
          <input type="hidden" name="requestUrlEncoded" :value="$route.query.requestUrlEncoded" v-if="$route.query.requestUrlEncoded"/>
          <input type="hidden" name="request" :value="$route.query.request" v-if="$route.query.request"/>
        </form>
      </div>
    </form-wrapper>
  </div>
  <div class="container" v-else>
    <heading size="l" class="heading">
      {{ $t('maintenance.header') }} 
    </heading>
    <div class="error">
      <span>{{ $t('maintenance.text') }}</span>
    </div>
  </div>
</template>

<script>
  import ModelSpecForm from "@/components/ModelSpecForm";
  import {RequestParsingService} from "@/views/ibis/requestParsing.service";
  import {ErrorService} from "@/error/error.service";
  import {EnvironmentService} from "@/environment";
  import {Button} from "@telenor/components-legacy/src/components";
  import FormWrapper from "@/components/FormWrapper";
  import Heading from "@/telenorComps/Heading/Heading";
  import InputText from "@/telenorComps/Input/InputText";
  import { isValidPhoneNumber} from 'libphonenumber-js';

  export default {
    name: "Identify",
    components: {
      FormWrapper,
      Heading,
      InputText,
      TButton: Button
    },
    async mounted() {
      this.environment = await EnvironmentService.getEnvironment();
      let requestParam = this.$route.query['requestUrlEncoded'];
      let redirectUrl = this.environment.onBlank;
      if (typeof requestParam === 'undefined' && !!redirectUrl) {
         window.location.href = redirectUrl;
         return;
      }
    },
    data() {
      return {
        loginHint: this.$route.query['loginHint'],
        tndAbout: '',
        environment: {}
      }
    },
    methods: {
      emitFormState(isValid) {
        this.$emit('update:formValid', isValid);
      },
      validation(value){
        let isValid = isValidPhoneNumber(value, 'NO') === true || this.validateNorwegianDataNumber(value) === true;
        this.emitFormState(isValid);
        return isValid;
      },
      validateNorwegianDataNumber(value){
        return (/^\d{12}$/.test(value) && (value.substring(0,2) == '58'));
      }
    },
    computed: {
      error() {
        let requestParam = this.$route.query['requestUrlEncoded'];
        let request = RequestParsingService.parseUrlEncodedJson(requestParam);

        if (request && request.errorCode) {
          window.console.debug(`IBIS error code: ${request.errorCode}`);

          let error = ErrorService.getError('ibis', request.errorCode);

          if (error.errorCode === 40002) {
            return this.$i18n.t('badInput');
          }

          return this.$i18n.t(error.i18nPath);
        }

        return '';
      },
      tndLink() {
        return `${this.environment.tnd}/about?locale=${this.$i18n.locale}`;
      },
      action() {
        return `/account/login`;
      }
    }
  }
</script>

<style lang="less" scoped>
 @import "./ibis-form-styles.less";
 @import "~@telenor/components-legacy/node_modules/@telenor/tokens/build/less/tokens.less";

 .button-icon {
   width: 18px;
   height: 18px;
   margin-bottom: -3px;
 }
 .iconbutton {
   background-image: url("../../assets/telenor-logo-flat-white.svg");
   background-repeat: no-repeat;
   background-size: 18px 18px;
   background-position: 10% center;
 }
</style>