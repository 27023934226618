<i18n>
{
  "no": {
    "title": "Registrer ny bruker",
    "ingress" : "Etter innsending av skjema vil du motta en kode på e-post og SMS for å aktivere konto.",
    "ingress-step2" : "Du skal nå motta en kode på SMS til mobilnummeret og en kode på epostadressen du oppga.",
    "create": "Opprett konto",
    "otpSMS": "Engangskode fra SMS",
    "otpEmail": "Engangskode fra epost",
    "validate": "Valider koder",
    "success" : "Kontoen er opprettet. Gå tilbake til",
    "login": "innlogging"
  },
  "en": {
    "title": "Register new account",
    "ingress" : "After submitting you will receive a one time code on email and on SMS to activate the account.",
    "ingress-step2" : "You should now receive a one time code on SMS and on email.",
    "create": "Create account",
    "otpSMS": "OTP from SMS",
    "otpEmail": "OTP from email",
    "validate": "Validate codes",
    "success" : "Account created. Go back to the",
    "login": "log in"
  }
}
</i18n>
<template>
  <div class="container">
    <form-wrapper>
      <heading size="xl" class="heading">{{ $t('title') }}</heading>
      <ErrorMessage :error="error"/>
      <div class="text" v-html="text" v-if="text"></div>
      <div class="input-fields" v-if="state === 0" key="zero">
        <p class="description">{{ $t('ingress') }}</p>
        <form @submit="onSubmit" method="post" id="reg">
          <input-text name="email" id="username" :text="usernameLabel" placeholder="oda@nordmann.no" validate-on-blur :required=true
                      v-model="model.email"/>
          <input-text name="phone" id="phone" :text="$t('fields.phone')" placeholder="+4755555555" validate-on-blur :required=true
                      v-model="model.phone"/>
          <InputPassword name="password" id="password" :text="$t('fields.password')" placeholder="*****" validate-on-blur
                         :required=true v-model="model.password"/>
          <InputPassword name="repeatPassword" id="repeatPassword" :text="$t('fields.passwordRepeat')" placeholder="*****" validate-on-blur
                         :required=true v-model="model.passwordRepeat"/>
          <div class="buttons">
            <t-button comp-type="submit-button" id="btnSubmitRegisterUser" :value="$t('create')" size="large" class="login-button"></t-button>
          </div>
          <PasswordRules/>
        </form>
      </div>
      <div class="input-fields" v-if="state === 1" key="one">
        <p class="description">{{ $t('ingress-step2') }}</p>
        <form @submit="onSubmitOtp" method="post" id="otp">
          <input-text name="smsOtp" :text="$t('otpSMS')" id="otpSMS" placeholder="******" validate-on-blur :required=true
                      v-model="smsOtp"
                      autocomplete="one-time-code"
                      pattern="[0-9]*"
                      inputmode="numeric"
          />
          <input-text name="emailOtp" :text="$t('otpEmail')" id="otpEmail" placeholder="******" validate-on-blur :required=true
                      v-model="emailOtp"
                      pattern="[0-9]*"
                      inputmode="numeric"
          />
          <div class="buttons">
            <t-button comp-type="submit-button" :value="$t('validate')" id="btnOtpSubmit" size="large" class="login-button"></t-button>
          </div>
        </form>
      </div>
      <div class="input-fields" v-if="state === 2" key="two">
        <p class="description">{{ $t('success') }}
          <router-link class="link" id="linkLogin" :to="{ name: 'elv-login', query: { request: request }}">{{ $t('login') }}
          </router-link>
        </p>
      </div>

    </form-wrapper>
  </div>
</template>

<script>
import {
  Button,
  Heading
} from "@telenor/components-legacy/src/components";
import FormWrapper from "@/components/FormWrapper";
import InputText from "@/telenorComps/Input/InputText";
import InputPassword from "@/telenorComps/Input/InputPassword";

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VueRouter from 'vue-router';
import {beginRegistration, finishRegistration} from "@/views/elvis/api";
import ErrorMessage from "@/views/elvis/components/errorMessage";
import PasswordRules from "@/views/elvis/components/PasswordRules";

const states = {
  BEGIN: 0,
  CODE_INPUT: 1,
  DONE: 2
}

export default {
  name: "ElvisRegister",
  components: { TButton: Button, Heading, InputText, FormWrapper, InputPassword, ErrorMessage, PasswordRules },

  data() {
    return {
      model: {
        email: "",
        phone: "",
        password: "",
        passwordRepeat: ""
      },
      text: null,
      error: null,
      state: states.BEGIN,
      smsOtp: "",
      emailOtp: ""
    }
  },
  computed: {
    request() {
      return this.$route.query['request'] || "";
    },
    usernameLabel() {
      return this.$t('fields.email') + " (" + this.$t('fields.username') + ")"
    }
  },
  methods: {
    onSubmit: function(e) {
      this.error = null;
      e.preventDefault();
      if (this.model.password !== this.model.passwordRepeat) {
        this.error = { type:"NOT_EQUAL", message: "Passwords must be equal" };
        return;
      }
      let dto = Object.assign({}, this.model);
      beginRegistration(dto).then(() => {
        this.smsOtp = "";
        this.emailOtp = "";
        this.state = states.CODE_INPUT
        this.error = null;
      }).catch(reason => {
        this.error = reason
      });
    },
    onSubmitOtp: function(e) {
      this.error = null;
      e.preventDefault();
      finishRegistration(this.model.email, this.smsOtp, this.emailOtp).then(() => {
        this.state = states.DONE
        this.error = null;
      }).catch(reason => {
        this.error = reason
      });
    },
    listenOtp() {
      if ('OTPCredential' in window) {
        const input = document.querySelector('input[autocomplete="one-time-code"]');
        if (!input) return;
        const ac = new AbortController();
        const form = input.closest('form');
        if (form) {
          form.addEventListener('submit', e => {
            ac.abort();
          });
        }

        navigator.credentials.get({
          otp: { transport:['sms'] },
          signal: ac.signal
        }).then(otp => {
          input.value = otp.code;
        }).catch(err => {
          console.log(err);
        });
      }
    }

  }
}
</script>

