<template>
  <div id="app">
    <div class="header"></div>
    <keep-alive include="SignUp">
        <router-view :key="$route.fullPath"></router-view>
      </keep-alive>
  </div>
</template>

<script>
export default {
  name: "default"
}
</script>

<style lang="less">
@import (reference) "~@telenor/tokens/build/less/variables.less";
@import "~@telenor/tokens/build/less/fontface";

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('assets/fonts/roboto-v20-latin/roboto-v20-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('assets/fonts/roboto-v20-latin/roboto-v20-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('assets/fonts/roboto-v20-latin/roboto-v20-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('assets/fonts/roboto-v20-latin/roboto-v20-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-family-regular: Roboto, "Helvetica Neue", Arial, sans-serif;

html, body, #__nuxt, #__layout {
  height: 100%;
}

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

html {
  font-family: @font-family-regular;
}

#app {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  background: #fff;
  height: 100%;
}

.header {
  width: 100%;
  height: 6rem;

  border-bottom: 1px solid #f5f5f5;
  background-image: url(./assets/telenor-logo-flat-blue.svg);
  background-size: 11.5rem 3rem;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

.link {
  color: @color-telenor-link;
  text-decoration: none;
  cursor: pointer;

  &
  :hover {
    text-decoration: underline;
  }
}

.container{
  width: 20rem;
  padding: 0 1rem;
  margin-left: auto;
  margin-right: auto;
}

</style>
