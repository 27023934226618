<div class="info-message"> Redirecting.. </div>

<script>
  import ModelSpecForm from "@/components/ModelSpecForm";
  import {RequestParsingService} from "@/views/ibis/requestParsing.service";
  import {DeviceFlowService} from "./device-flow.service";
  import {UserManager, WebStorageStateStore} from 'oidc-client';
  import {EnvironmentService} from "@/environment";
  import {Button} from "@telenor/components-legacy/src/components";
  import FormWrapper from "@/components/FormWrapper";
  import Heading from "@/telenorComps/Heading/Heading";
  import InputText from "@/telenorComps/Input/InputText";
  import UrlForm from "@/components/UrlForm";

  export default {
    name: "LoginConfig",
    components: {
      FormWrapper,
      Heading,
      TButton: Button,
      UrlForm
    },
    created(){
      this.deviceFlowService = new DeviceFlowService();
    },

    async mounted() {
        this.userCode = this.$route.query['userCode'];
        let response = await this.deviceFlowService.getDeviceRequest(this.userCode);
        this.clientId = response.clientId;
        this.scopes = response.scopes;
        
        let mgr = this.getUserManager();
        let user = await mgr.getUser();
    
        this.environment = await EnvironmentService.getEnvironment();

        if (user) {
          this.user = user;
        }

        return this.handleGoClick();
  },

    data() {
      let options = this.getDefaultOptions();
    
      return {
        environment: {},
        userCode: null,
        clientId: null,
        scopes: null,
        options: options,
        info: null

      }
    },
    methods: {
      async handleGoClick() {

      localStorage.setItem('options', JSON.stringify(this.options));
      let mgr = this.getUserManager();

      await mgr.signinRedirect({
        login_hint: this.options.loginHint,
        acr_values: this.options.acr + (this.options.idp ? ` idp:${this.options.idp}` : ``),
        state: this.userCode, // for retrieval at callback
        extraQueryParams: {
          amr: this.options.amr || "",
          ui_locales: this.options.language || "no en",
          context: this.options.loginContext || "login",
          user_code: this.userCode
        }
      });
    },
    
    getDefaultOptions() {
      return {
        clientId: this.clientId,
        loginHint: '',
        overrideCallbackUrl: '',
        acr: '',
        idp: '',
        responseType: 'code',
        scope: this.scopes
      };
    },
    getUserManager() {
      return new UserManager({
        authority: this.environment.value,
        client_id: this.options.clientId || this.clientId,
        redirect_uri: this.options.overrideCallbackUrl || this.callbackUrl,
        response_type: 'code',
        scope: this.options.scope || this.scopes,
        post_logout_redirect_uri: this.postLogoutUrl,
        userStore: new WebStorageStateStore({store: window.localStorage})
      });
    },
  },
    computed: {
      callbackUrl() {
      return `${this.environment.value}/ui/device/callback`;
    },
    }
  }
</script>

<style lang="less" scoped>
 @import "../ibis-form-styles.less";
 @import "~@telenor/components-legacy/node_modules/@telenor/tokens/build/less/tokens.less";

 .button-icon {
   width: 18px;
   height: 18px;
   margin-bottom: -3px;
 }
 .iconbutton {
   background-image: url("../../../assets/telenor-logo-flat-white.svg");
   background-repeat: no-repeat;
   background-size: 18px 18px;
   background-position: 10% center;
 }
</style>