<i18n>
{
  "no": {
    "PW_RULES": {
      "TITLE": "Passordregler",
      "1" : "Minst 8 tegn",
      "2" : "Ikke en dato",
      "3" : "Ikke for mange tegn som følger hverandre i alfabetet",
      "4" : "Ikke for mange tegn som er ved siden hverandre på tastaturet",
      "5" : "Ikke et passord som er mye brukt og kjent fra passordlekasjer"
    }
  },
  "en": {
    "PW_RULES": {
      "TITLE": "Password rules",
      "1" : "At least 8 characters",
      "2" : "Not a date",
      "3" : "Not too many alphabetical neighbours in a row",
      "4" : "Not too many keyboard neighbours in a row",
      "5" : "Not a common password found in data breaches"
    }
  }
}
</i18n>

<template>
  <details>
    <summary>{{title}}</summary>
    <ul>
      <li v-for="rule in rules" :key="rule">{{rule}}</li>
    </ul>
  </details>
</template>

<script>
export default {
  name: "PasswordRules",

  computed: {
    title() {
      return this.$t("PW_RULES.TITLE")
    },
    rules() {
      let r = [];
      for(let i = 1; i <= 5; i++) {
        r.push(this.$t(`PW_RULES.${i}`))
      }
      return r;
    }
  }
}
</script>

<style scoped>
details {
  font-family: Telenor;
  padding: 0.5em 0em;
}
details summary {
  cursor: pointer;
}
details ul {
  list-style: disc;
  padding-left: 2.25em;
}
</style>
