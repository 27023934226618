<template>
    <form-wrapper>
      <heading :size="model.headerSize || 'xl'" class="heading">
        {{ model.header }}
      </heading>
      <div class="error" v-if="model.error">
            {{model.error}}
        </div>
        <div class="text" v-html="model.text" v-if="model.text"></div>
        <div class="input-fields">
            <form method="post" :action="action || model.action">
                <div v-for="field in model.fields" :key="field.name">
                  <component  :is="field.type"
                              v-bind="field"
                              validate-on-blur
                  />
                </div>

                <button v-if="model.buttonIcon" class="button login-button">
                  <img class="button-icon" src="../assets/telenor-logo-flat-white.svg" alt="telenor logo white" />
                    {{model.button}}
                </button>

                <t-button v-else comp-type="submit-button"
                          :size="model.buttonSize || 'large'" class="login-button"
                          :fluid="model.buttonFluid"
                          :value="model.button"
                />
                <div class="bottom-link" v-if="model.bottomLink">
                    <t-link :href="model.bottomLink.url">{{model.bottomLink.text}}</t-link>
                </div>
                <input type="hidden" name="requestUrlEncoded" :value="$route.query.requestUrlEncoded" v-if="$route.query.requestUrlEncoded"/>
                <input type="hidden" name="request" :value="$route.query.request" v-if="$route.query.request"/>
            </form>
        </div>
    </form-wrapper>
</template>

<script>

    import {
        Button,
        InputPhone,
        InputEmail,
        Link,
        Paragraph,
    } from "@telenor/components-legacy/src/components";
    import InputText from "@/telenorComps/Input/InputText";
    import Heading from "@/telenorComps/Heading/Heading";

    import FormWrapper from "./FormWrapper";
    import InputHidden from "./InputHidden";
    import TextBlock from "./TextBlock";

    export default {
        name: "ModelSpecForm",
        components: {
            FormWrapper,
            TButton: Button,
            Heading,
            InputText,
            InputEmail,
            InputPhone,
            TLink: Link,
            Paragraph,
            InputHidden,
            TextBlock
        },
        props: ["model", "action"]
    }
</script>

<style lang="less" scoped>
    @import (reference) "~@telenor/tokens/build/less/variables.less";

    ::v-deep .input-fields {

        input[type=submit] {
            margin: 20px auto 0;
            display: block;
        }
        .input__error {

        }
    }

    .heading {
        margin: 50px auto;
        display: block;
        text-align: center;
    }
    .field-info-message {
      display: block;
      font-size: @font-size-desktop-sm;
      margin: @spacing-sm 0px @spacing-lg 0px;
      color: @color-gray-dark;
      @media screen and (max-width: 768px) {
        font-size: @font-size-mobile-sm;
      }
    }
    button,
    .button {
      display: inline-block;
      border-radius: 4px;
      border: none;
      font-family: @font-family-telenor-regular;
      cursor: pointer;
      position: relative;
      padding: @spacing-md @spacing-xl; // Default mobile
      box-sizing: border-box;
      font-size: @font-size-desktop-xs;
      text-decoration: none;
      text-align: center;
      transition: background-color .3s ease-out;
      width: 100%;
      background-color: @color-telenor-link;
      color: @color-white;

      @media screen and (min-width: @size-screen-phone) {
        padding: @spacing-sm @spacing-xl; // Default desktop
      }

      &:hover {
        background-color: darken(@color-telenor-link, 15%);
      }
    }

    .login-button {
      padding: @spacing-md @spacing-xl;
      font-size: @font-size-desktop-sm;
    }
    .button-icon {
      width: 18px;
      height: 18px;
      margin-bottom: -3px;
      ::v-deep svg g {
        fill: white;
      }

    }
    @media screen and (max-width: 768px) {
        ::v-deep .input-fields {
            min-width: 0;
            width: 100%;

            form {
                label + label {
                    margin-top: 30px;
                }
            }
        }
    }

    .bottom-link {
        text-align: center;
        margin: 20px 0 0;
    }

    .text {
        margin-bottom: 50px;
        font-family: "Source Sans Pro", sans-serif;
    }

    ::v-deep .error {
        padding: 10px 15px;
        font-family: @asset-font-telenor-name;
        font-size: 14px;
        background: @color-telenor-blue;
        border-radius: 7px;
        margin-bottom: 50px;
        text-align: center;
    }

</style>
