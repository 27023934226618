import Vue from 'vue';
import VueRouter from 'vue-router';
import Identify from '../views/ibis/Identify.vue';
import Callback from '../views/debugger/Callback.vue';
import IbisLogin from '../views/ibis/login/Login.vue';
import IbisLogout from '../views/ibis/Logout.vue';
import CorrelationFailed from '../views/ibis/CorrelationFailed.vue'
import MtlsFailed from '../views/cassowary/MtlsFailed.vue'
import Debugger from '../views/debugger/Debugger.vue';
import KookaburraIdentify from '../views/kookaburra/Identify.vue';
import KookaburraLogin from '../views/kookaburra/Login.vue';
import KookaburraError from '../views/kookaburra/OtpError.vue';
import ElvisLogin from '../views/elvis/login.vue';
import ElvisRegister from '../views/elvis/register.vue';
import ElvisForgotUsername from '../views/elvis/forgot/username.vue';
import ElvisForgotPassword from '../views/elvis/forgot/password.vue';
import ElvisSetPassword from '../views/elvis/setNewPassword.vue';
import qs from 'qs';
import {setI18nLanguage} from "@/main";
import SignUp from "@/views/ibis/sign-up/SignUp.vue";
import VerifyUser from "@/views/ibis/sign-up/VerifyUser.vue";
import NationalIdInfo from "@/views/ibis/NationalIdInfo.vue";
import CancelWarning from "@/views/ibis/CancelWarning.vue";
import {RequestParsingService} from "@/views/ibis/requestParsing.service";
import Validate from "@/views/ibis/sign-up/Validate.vue";
import StepUp from "@/views/ibis/step-up/StepUp.vue";
import Activate from "@/views/ibis/device-flow/Activate.vue";
import DeviceCallback from "@/views/ibis/device-flow/Consent.vue";
import DeviceLogin from "@/views/ibis/device-flow/Login.vue";
import DeviceSuccess from "@/views/ibis/device-flow/Success.vue";
import DeviceFailure from "@/views/ibis/device-flow/Failure.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'identify',
    component: Identify,
    props: true,
  },
  {
    path: '/ibis/national-id-info',
    name: 'ibis-national-id-info',
    component: NationalIdInfo,
    props: true,
  },
  {
    path: '/ibis/sign-up',
    name: 'ibis-sign-up-phone',
    component: SignUp,
    props: true,
  },
  {
    path: '/ibis/sign-up/person',
    name: 'ibis-sign-up-person',
    component: SignUp,
    props: true,
  },
  {
    path: '/ibis/sign-up/verify',
    name: 'ibis-sign-up-verify',
    component: VerifyUser,
    props: true,
  },
  {
    path: '/ibis/sign-up/validate',
    name: 'ibis-sign-up-validate',
    component: Validate,
    props: true,
  },
  {
    path: '/ibis/step-up',
    name: 'ibis-step-up',
    component: StepUp,
    props: true
  },
  {
    path: '/ibis/login',
    name: 'ibis-login',
    component: IbisLogin,
    props: true,
  },
  {
    path: '/ibis/cancel-warning-info',
    name: 'ibis-cancel-warning',
    component: CancelWarning,
    props: true,
  },
  {
    path: '/ibis/logout',
    name: 'ibis-logout',
    component: IbisLogout,
    props: true,
  },
  {
    path: '/ibis/correlation-failed',
    name: 'correlation-failed',
    component: CorrelationFailed,
    props: true,
  },
  {
    path: '/cas/mtls-failed',
    name: 'mtls-failed',
    component: MtlsFailed,
    props: true,
  },
  {
    path: '/debugger',
    name: 'debugger',
    component: Debugger,
    props: true,
  },
  {
    path: '/debugger/callback',
    name: 'oauth-callback',
    component: Callback,
    props: true,
  },
  {
    path: '/koo/identify',
    name: 'koo-identify',
    component: KookaburraIdentify,
    props: true,
  },
  {
    path: '/koo/login',
    name: 'koo-login',
    component: KookaburraLogin,
    props: true,
  },
  {
    path: '/koo/error',
    name: 'koo-error',
    component: KookaburraError,
    props: true,
  },
  {
    path: '/elv/login',
    name: 'elv-login',
    component: ElvisLogin,
    props: true,
  },
  {
    path: '/elv/register',
    name: 'elv-register',
    component: ElvisRegister,
    props: true,
  },
  {
    path: '/elv/forgot/username',
    name: 'elv-forgot-username',
    component: ElvisForgotUsername,
    props: true,
  },
  {
    path: '/elv/forgot/password',
    name: 'elv-forgot-password',
    component: ElvisForgotPassword,
    props: true,
  },
  {
    path: '/elv/forgot/set-password',
    name: 'elv-set-password',
    component: ElvisSetPassword,
    props: true,
  },
  {
    path: '/device/activate',
    name: 'Activate',
    component: Activate,
    props: true,
  },
  {
    path: '/device/login',
    name: 'DeviceLogin',
    component: DeviceLogin,
    props: true,
  },
  {
    path: '/device/callback',
    name: 'oath-callback',
    component: DeviceCallback,
    props: true,
  },
  {
    path: '/device/success',
    name: 'Success',
    component: DeviceSuccess,
    props: true,
  },
  {
    path: '/device/failure',
    name: 'Failure',
    component: DeviceFailure,
    props: true,
  }
];

const router = new VueRouter({
  mode: 'history',
  base: "/ui",
  parseQuery: (query: any): object => { // IDE doesn't show prop as active, but it is
    return qs.parse(query);
  },
  stringifyQuery(query: any): string {
    let result = qs.stringify(query, {encode: false});
    return result ? ('?' + result) : '';
  },
  routes,
});

router.beforeEach((to, from, next) => {
  const lsKey = 'ui_locales';
  const defaultLang = 'no';
  let uiLocales = to.query.ui_locales;
  let lang = window.localStorage.getItem(lsKey) || defaultLang;

  if (typeof uiLocales === 'string') {
    lang = uiLocales.split(' ', 1)[0];
  } else {
    let requestParams = to.query['requestUrlEncoded'];
    let request = RequestParsingService.parseUrlEncodedJson(requestParams);

    if (request && request.language) {
      lang = request.language;
    }
  }
  if (lang.indexOf(" ")) {
    lang = lang.split(" ")[0]
  }
  if (lang !== defaultLang) {
    window.localStorage.setItem(lsKey, lang);
  }

  window.console.debug(`Setting UI language to ${lang}`);
  setI18nLanguage(lang);

  next();
})

export default router;
