import Vue from 'vue';
import Vuex from 'vuex';
import {ApiError} from "@/types"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    model: {
      signUpRequest: {},
      error: null
    }
  },
  mutations: {
    setSignUpRequest(state, signUpRequest) {
      state.model.signUpRequest = signUpRequest
    },
    setError(state: any, error: ApiError) {
      console.log(`Setting error to #${error.errorCode}; ${error.i18nPath}`);
      state.model.error = error;
    }
  },
  actions: {},
  modules: {},
});

interface LooseObject {
  [key: string]: any
}
