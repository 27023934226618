<i18n>
{
  "no": {
    "TOO_MANY_USERS": "Fant for mange brukere. Vennligst kontakt support",
    "TITLE": "Glemt passord",
    "SUCCESS": "Hvis brukernavnet finnes, sendes det om kort tid instruksjoner om tilbakestilling til registrert e-post. Vennligst gå tilbake til",
    "LOGIN" : "innlogging"
  },
  "en": {
    "TOO_MANY_USERS": "Found too many users. Please contact support.",
    "TITLE": "Forgot password",
    "SUCCESS": "If the username exists we will send password reset instructions to the registered email. Please go back to",
    "LOGIN" : "log in"
  }
}
</i18n>
<template>
  <div class="container">
    <form-wrapper>
      <heading size="xl" class="heading">{{ $t('TITLE') }}</heading>
      <ErrorMessage :error="error"/>
      <div class="text" v-html="text" v-if="text"></div>
      <div class="input-fields">
        <forgot-password-form v-if="contactInfo == null && !successSent" @formSubmitted="onSubmitUsername"/>
        <div v-if="successSent" class="text">
          <p class="description">{{ $t('SUCCESS') }}
            <router-link class="link" :to="{ name: 'elv-login', query: { request: request }}">{{ $t('LOGIN') }}
            </router-link>
          </p>
        </div>
      </div>
    </form-wrapper>
  </div>
</template>

<script>
  import {
      Heading
  } from "@telenor/components-legacy/src/components";
  import FormWrapper from "@/components/FormWrapper";
  import { forgotPassword } from "../api";
  import ForgotPasswordForm from "../components/forgotPasswordForm.vue";
  import ErrorMessage from "@/views/elvis/components/errorMessage";


  export default {
    name: "ElvisForgotPassword",
    components: {
        FormWrapper,
        Heading,
        ForgotPasswordForm,
        ErrorMessage
    },
    data() {
      return {
        text: null,
        username: "",
        error: null,
        contactInfo: null,
        successSent: false
      }
    },
    computed : {
      request() {
        return this.$route.query['request'] || "";
      }
    },
    methods: {
      onSubmitUsername: function(username) {
        this.error = null;
        this.contactInfo = null;
        this.username = username;
        forgotPassword(username).then(result => {
          this.successSent = true;
        }).catch(err => this.error = err);
      },
    }
  }
</script>
