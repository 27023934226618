<i18n>
{
  "no": {
  "whyDoWeNeedYourNationalIdNumber": "Hvorfor trenger vi fødselsnummeret ditt?",
  "info": "For Telenors tjenester er vi påbelagt av norske myndigheter å verifisere din identitet. Derfor trenger vi å bekrefte fødselsnummeret ditt mot Folkeregisteret.",
  "info2": "For å forhindre svindel varsler vi deg ved å sende en SMS til mobilnummeret tilknyttet ditt fødselsnummer.",
  "privacy": "Les mer om personvern",
  "back": "Tilbake"
  },
  "en": {
  "whyDoWeNeedYourNationalIdNumber": "Why do we need your national ID number?",
  "info": "Telenor are required by the Norwegian authorities to verify your identity. Therefore we need to verify your national identification number in the National Registry.",
  "info2": "To prevent fraud we alert you by sending an SMS to the mobile phone numbers linked to your national ID.",
  "privacy": "Read more about privacy",
  "back": "Back"
  }
}
</i18n>
<template>
  <section class="info-screen">
    <heading size="l">{{$t("whyDoWeNeedYourNationalIdNumber")}}</heading>
    <p>{{$t("info")}}</p>
    <p>{{$t("info2")}}</p>
    <p>
      <a v-bind:href="environment.privacyUrl">{{$t("privacy")}}</a>
    </p>
    <a class="link" @click="$router.back()">
      <img class="chevron-left" src="@/assets/chevron_left.svg" alt="chevron left">
      {{$t("back")}}
    </a>
    <img class="logo" src="@/assets/telenor-logo-flat-blue.svg" alt="telenor logo blue"/>
  </section>
</template>
<script>
import {Heading} from "@telenor/components-legacy/src/components";
import {EnvironmentService} from "@/environment";

export default {
  name:"NationalIdInfo",
  components: {Heading},
  async mounted() {
    this.environment = await EnvironmentService.getEnvironment();
  },
  data() {
      return {
        environment: {}
      }
    },
}
</script>

<style lang="less" scoped>
  @import "./ibis-form-styles.less";
</style>
