<template>
  <form @submit="onSubmit">
    <slot/>
  </form>
</template>

<script>
  export default {
    name: "UrlForm",
    props: {
      onPost: Function,
      onError: Function,
      model: Object
    },
    methods: {
      async onSubmit(e) {
        e.preventDefault();
        let formData = new FormData(e.target);
        let qs = '';
        formData.forEach((key, value) => qs += `${value}=${encodeURIComponent(key)}&`);
        this.onPost(qs, e);
      }
    }
  }
</script>

