<i18n>
{
  "no": {
    "errors": {
      "expired": "Økten har utløpt. Lukk nettleser og forsøk igjen.",
      "ninMismatch": "Vi fant deg ikke i folkeregisteret. Kontakt kundeservice.",
      "signUpLocked": "Noe gikk galt. Lukk nettleser og prøv igjen.",
      "invalidForm": "Alle påkrevde felter må fylles ut.",
      "badState": "Vi kunne ikke fullføre registrering av konto. Vennligst kontakt kundeservice for videre hjelp.",
      "tryAgain": "Vennligst prøv på nytt",
      "contactCustomerService": "Kontakt kundeservice"
    },
    "areYou": "Hei,",
    "confirmUser": "Bekreft personalia",
    "unknownNumber": "Ikke ditt nummer? {0}",
    "unknownPerson": "Ikke deg? {0}",
    "logOut": "Logg ut",
    "notYou": "Ikke deg? Klikk her",
    "firstAndMiddleName": "For- og mellomnavn",
    "surname": "Etternavn",
    "submit": "Bekreft identitet",
    "youAreLoggingInWith": "Du logger inn med Telenor ID med",
    "cancel": "Avbryt",
    "loading": "Laster ...",
    "continueWithoutSignUp": "Fortsett uten fødselsnummer",
    "kyc": {
      "header": "Bekreft din identitet"
    },
    "description": "I henhold til norsk lov må vi verifisere din identitet når du bruker våre tjenester. Om du f.eks. gjør en endring på et abonnement eller handler i nettbutikken, skal du være trygg på at sikkerheten din blir ivaretatt."
  },
  "en": {
    "errors": {
      "expired": "This session has expired. Please close this browser window and try again.",
      "ninMismatch": "We can't find you in the National Registry. Please contact customer service.",
      "signUpLocked": "Something went wrong. Please close this browser window and try again.",
      "invalidForm": "All mandatory fields have to be filled.",
      "badState": "We are unable to complete registration of this account. Please contact customer service.",
      "tryAgain": "Please try again",
      "contactCustomerService": "Please contact customer service"
    },
    "areYou": "Hi,",
    "confirmUser": "Confirm user",
    "unknownNumber": "Not your number? {0}",
    "unknownPerson": "Not you? {0}",
    "logOut": "Log out",
    "notYou": "Not you? Click here",
    "firstAndMiddleName": "First and middle name",
    "surname": "Surname",
    "submit": "Confirm identity",
    "youAreLoggingInWith": "You are logging in with Telenor ID as",
    "cancel": "Cancel",
    "loading": "Loading ...",
    "continueWithoutSignUp": "Continue without national ID Number",
    "kyc": {
      "header": "Verify your identity"
    },
    "description": "According to Norwegian law we need to verify your identity when you are using our services. If you are doing any changes to your subscription or shopping in the online store, you should be confident that your safety is taken care of."
  }
}
</i18n>

<template>
  <div class="container" v-if='!isUsingOldSignupFlow'>
    <form-wrapper>
      <heading size="l" class="heading">
        {{ $t('kyc.header') }} 
      </heading>
      <div class="error" v-if="error">
        <div v-if="errorLink">
          <a v-bind:href="errorLink">{{ $t(error) }}</a>
        </div>
        <div v-else>
          <span>{{ $t(error) }}</span> 
        </div>
       
      </div>
      <div v-if="isValidIdps">
        <idp v-for="idp in idps" :key="idp.displayName" :idp="idp" :url="getIdpUrl(idp)"/>
        <url-form :on-post="this.onSubmit">
          <t-button comp-type="submit-button" size="medium" fluid class="login-button cancel-signup" id="btnContinueWithoutSignup"
                    :value="$t('continueWithoutSignUp')" :disabled="isLoading" v-if="setupSignUpRequest.canCancelSignUp" />
        </url-form>
      </div>
      <div class="heading">
        {{ $t("description") }}
      </div>
    </form-wrapper>
  </div>
  <div v-else>
    <form-wrapper>
      <div class="success">
        {{ signUpRequest.signUpId || signUpRequest.msisdn ? $t('youAreLoggingInWith') : $t('loading')}}
        <div>
          <msisdn v-bind:number="signUpRequest.msisdn"/>
        </div>
      </div>
      <heading size="m" class="heading">
        {{ isSignUpByPhone ? `${$t('areYou')} ${fullName}` : $t('confirmUser') }}
      </heading>

      <router-link v-if="isSignUpByPhone" class="link"
                  :to="{ name: 'ibis-sign-up-person', query: { request: this.signUpRequestId }}">
        {{ $t('notYou') }}
      </router-link>

      <div class="error" v-if="error">
        <span>{{ $t(error) }}</span>
      </div>

      <div class="input-fields">
        <url-form :on-post="this.onSubmit">
          <validate-number-form :sign-up-request="signUpRequest" v-if="isSignUpByPhone" v-bind:form-valid.sync="formValid"/>
          <validate-person-form :sign-up-request="signUpRequest" v-if="!isSignUpByPhone" v-bind:form-valid.sync="formValid"/>

          <t-button comp-type="submit-button" size="medium" fluid class="login-button" id="btnSignupSubmit"
                    :value="$t('submit')" :disabled="isLoading" />
          <router-link class="button cancel" id="btnSignupCancel"
                      :to="{name: 'ibis-cancel-warning', query: { request: this.signUpRequestId }}">
            {{ $t("cancel") }}
          </router-link>
        </url-form>
      </div>
    </form-wrapper>
  </div>
</template>
<script>
import FormWrapper from "@/components/FormWrapper";
import {SignUpService} from "./signup.service"
import Msisdn from "@/components/Msisdn";
import {Button} from "@telenor/components-legacy/src/components";
import Heading from "@/telenorComps/Heading/Heading";
import ValidateNumberForm from "@/views/ibis/sign-up/ValidateNumberForm";
import ValidatePersonForm from "@/views/ibis/sign-up/ValidatePersonForm";
import UrlForm from "@/components/UrlForm";
import {EnvironmentService} from "@/environment";
import {IdpService} from '@/views/ibis/login/idp.service';
import Idp from "@/components/Idp";
import {ErrorService} from "@/error/error.service"
import {RequestParsingService} from "@/views/ibis/requestParsing.service";

export default {
  name: "SignUp",
  components: {
    UrlForm,
    ValidateNumberForm,
    ValidatePersonForm,
    FormWrapper,
    TButton: Button,
    Heading,
    Msisdn,
    Idp
  },
  data() {
    return {
      signUpRequestId: "",
      signUpRequest: {},
      setupSignUpRequest: {},
      formValid: false,
      fullName: "",
      error: null,
      isLoading: false,
      environment: {},
      idps: [],
      loginContext: '',
      isValidIdps: false,
      request: null,
      errorLink: '',
      useBankIdSignUp: false
    }
  },
  methods: {
    handleError(error) {
      this.error = error.i18nPath;
      if(error.hasLink) {
        let lang = this.$i18n.locale;
        this.errorLink = this.environment.ksUrlEn
        if (lang === 'no') {
          this.errorLink = this.environment.ksUrlNo
        }
      }
    },
    async setupBankIdSignUp(){
      let setupSignUpRequest = await this.signUpService.getSetupForSignUp(this.signUpRequestId);
      
      this.loginContext = 'kyc';
      this.idps = new IdpService().getIdps(['no.bankid.vipps']);

      if (this.errorCode && (setupSignUpRequest.errorCode === 50000 || !setupSignUpRequest.errorCode)) {
        let error = ErrorService.getError('ibis', this.errorCode);
        this.handleError(error);
        return;
      }

      if (setupSignUpRequest.errorCode) {
        this.handleError(setupSignUpRequest);
        return;
      }
      this.setupSignUpRequest = setupSignUpRequest;
      this.request =  RequestParsingService.parseUrlEncodedJson(this.setupSignUpRequest.encodedQuery) || {};
      
      if (this.setupSignUpRequest.encodedQuery) {
        this.isValidIdps = true;
      }

    },
    async onSubmit(qs, e) {
      if(e.submitter.id === 'btnContinueWithoutSignup'){
        this.isLoading = true;
        let response = await this.signUpService.cancelSignUp(this.signUpRequestId);

        this.isLoading = false;
        if (response && response.errorCode) {
          this.handleError(response);
          this.formValid = true;
        }
        if (response.verificationCodeRequired === false && response.location) {
          window.location = response.location;
        }

        return
      }
      
      if(!this.formValid){
        this.error = "errors.invalidForm"
        return
      }

      this.error = null;
      this.formValid = false;
      this.isLoading = true;
      let response = await this.signUpService.sendSignUpRequest(this.signUpRequestId, qs);
      this.isLoading = false;

      if (response && response.errorCode) {
        this.handleError(response);
        this.formValid = true;
      } else {
        if (response.verificationCodeRequired === false && response.location) {
          window.location = response.location;
        } else {
          return this.$router.push({
            name: 'ibis-sign-up-verify',
            query: {codeId: response.verificationCodeId}
          });
        }
      }
    },
    getIdpUrl(idp) {
      let idpRequest = Object.assign({}, this.request);
      idpRequest.authScheme = idp.authScheme;
      idpRequest.idps = null;

      let signUpRequest = this.setupSignUpRequest;

      if (!idpRequest.authorizeParams) {
        idpRequest.authorizeParams = {};
      }

      if (idp.amr) {
        window.console.debug(`Setting AMR to ${idp.amr}`)
        idpRequest.authorizeParams.amr = idp.amr;
      }

      let encoded = RequestParsingService.toUrlEncodedJson(idpRequest);
      return `/sign-up/challenge?requestUrlEncoded=${encoded}`
    }
  },
  computed: {
    isSignUpByPhone() {
      return this.$route.name === 'ibis-sign-up-phone';
    },
    isUsingOldSignupFlow() {
      let signUpFlow = this.environment.useOldSignUpFlow
      if (signUpFlow === 'true') {
        return true;
      }
      return false;
    }
  },
  created() {
    this.signUpService = new SignUpService();
    this.signUpRequestId = this.$route.query.request;
    this.errorCode =  this.$route.query.error;
  },
  async mounted() {
    this.environment = await EnvironmentService.getEnvironment();

    if (this.isUsingOldSignupFlow) {
      let signUpRequest = await this.signUpService.getSignUpRequest(this.signUpRequestId);

      if(signUpRequest.isBankIdSignUp) {
        this.useBankIdSignUp = true;
        this.setupBankIdSignUp();
        return;
      }
      else {
        if (signUpRequest.errorCode) {
          this.handleError(signUpRequest);
          return;
        }

        this.signUpRequest = signUpRequest;

        if (signUpRequest && signUpRequest.lastName) {
          this.fullName = `${signUpRequest.firstAndMiddleName} ${signUpRequest.lastName}`;
        }

        if (!signUpRequest.lastName && this.$route.name !== 'ibis-sign-up-person') {
          return this.$router.push({
            name: 'ibis-sign-up-person',
            query: {request: this.signUpRequestId}
          });
        }
      }
    } else {
      this.setupBankIdSignUp();
      return;
    }
  }
}
</script>
<style lang="less" scoped>
@import "../ibis-form-styles.less";

.heading {
  margin-bottom: @spacing-sm;
}

.button.cancel,
.cancel-signup {
  margin-top: @spacing-sm;
}

.link {
  display: block;
  text-align: center;
}
</style>
