<template>
  <form-wrapper style="width:600px" @submit="handleGoClick">
    <heading size="xl" class="heading">Debugger</heading>
    <heading size="l" class="heading">Environment: {{ environment.label }} - {{ environment.value }}</heading>
    <div>
      <label>Client ID
        <input type="text" id="txtClientId" v-model="options.clientId"/>
      </label>
    </div>
    <div>
      <label>Callback URI
        <input type="text" id="txtRedirectUri" v-model="options.overrideCallbackUrl"
               :placeholder="callbackUrl"/>
      </label>
    </div>
    <div>
      <label>
        Scope
        <input type="text" id="txtScopes" v-model="options.scope"/>
      </label>
    </div>
    <div>
      <label>
        Login hint
        <input type="text" id="txtLoginHint" v-model="options.loginHint"/>
      </label>
    </div>
    <div>
      <label>
        Force IdP
        <input type="text" id="txtForceIdp" v-model="options.idp" placeholder="Can be left blank"/>
      </label>
    </div>
    <div>
      <label>
        Authentication Context Class Reference (ACR)
        <input type="text" id="txtAcr" v-model="options.acr" placeholder="Can be left blank"/>
      </label>
    </div>
    <div>
      <label>
        Login Context
        <input type="text" id="txtLoginContext" v-model="options.loginContext" placeholder="Can be left blank"/>
      </label>
    </div>
    <div>
      <label>
        Language
        <input type="text" id="txtLanguage" v-model="options.language" placeholder="Can be left blank"/>
      </label>
    </div>
    <div>
      <button type="button" id="btnAuthorize" v-on:click="handleGoClick">Go</button>
    </div>
    <div>
      <button type="button" class="clear" v-on:click="clearLs">Clear</button>
    </div>
    <div>
      <button type="button" class="log-out" v-on:click="logOut">Log out</button>
    </div>
  </form-wrapper>
</template>

<script>
import FormWrapper from "./FormWrapper";

import {
  Heading
} from '@telenor/components-legacy/src/components';
import {UserManager, WebStorageStateStore} from 'oidc-client';
import {EnvironmentService} from "@/environment";

export default {
  name: "DebuggerConfig",
  components: {
    FormWrapper, Heading
  },
  props: ['model'],
  computed: {
    callbackUrl() {
      return `${this.environment.value}/ui/debugger/callback`;
    },
    postLogoutUrl() {
      return `${this.environment.value}/ui/debugger?logout=ok`;
    }
  },
  data() {
    let lsOptions = localStorage.getItem('options');
    let options = lsOptions ? JSON.parse(lsOptions) : this.getDefaultOptions();

    return {
      environment: {},
      options: options,
      user: null
    }
  },
  methods: {
    async handleGoClick() {
      localStorage.setItem('options', JSON.stringify(this.options));
      let mgr = this.getUserManager();

      await mgr.signinRedirect({
        login_hint: this.options.loginHint,
        acr_values: this.options.acr + (this.options.idp ? ` idp:${this.options.idp}` : ``),
        extraQueryParams: {
          amr: this.options.amr || "",
          ui_locales: this.options.language || "no en",
          context: this.options.loginContext || "login"
        }
      });
    },
    getDefaultOptions() {
      return {
        clientId: "demo-client",
        loginHint: "99966634",//"90134687",
        overrideCallbackUrl: '',
        acr: '',
        idp: '',
        responseType: 'code',
        scope: "openid profile ial2"
      };
    },
    clearLs() {
      window.localStorage.clear();
      this.options = this.getDefaultOptions();
    },
    async logOut() {
      let mgr = this.getUserManager();
      await mgr.signoutRedirect();
    },
    getUserManager() {
      return new UserManager({
        authority: this.environment.value,
        client_id: this.options.clientId,
        redirect_uri: this.options.overrideCallbackUrl || this.callbackUrl,
        response_type: 'code',
        scope: this.options.scope,
        post_logout_redirect_uri: this.postLogoutUrl,
        userStore: new WebStorageStateStore({store: window.localStorage})
      });
    },
    parseJwt(jwt) {
      let base64Url = jwt.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }
  },
  async mounted() {
    let mgr = this.getUserManager();
    let user = await mgr.getUser();
    this.environment = await EnvironmentService.getEnvironment();

    if (user) {
      this.user = user;
    }

  }
}
</script>

<style lang="less" scoped>
@import (reference) "~@telenor/tokens/build/less/variables.less";

div {
  margin: 1.2em 0;

  select, label, input, button {
    width: 90%;
    position: relative;
    font-family: @asset-font-telenor-light-name;
    margin: 0;
  }

  button {
    background: #007ad0;
    color: #fff;
    border-radius: 10px;
    margin: 20px auto 0px;
    display: block;
  }

  button.log-out {
    background: #fc5145;
  }

  button.clear {
    background: #ff9326;
  }

  select, button {
    width: 100%;
    padding: 8px 0;
    font-size: 18px;
    border: none;
    border-bottom: 1px solid #d6d6d6;
    caret-color: #19aaf8;
  }

  input {
    &[type="text"],
    &[type="email"],
    &[type="tel"],
    &[type="url"],
    &[type="password"],
    &[type="date"] {
      width: 100%;
      padding: 8px 0;
      font-size: 18px;
      border: none;
      border-bottom: 1px solid #d6d6d6;
      caret-color: #19aaf8;

      &:focus {
        outline: none;
        border-bottom: 1px solid #19aaf8;
      }
    }
  }
}

.heading {
  margin: 50px auto;
  display: block;
  text-align: center;
}
</style>
