export class Idp {
  constructor(
    public authScheme: string,
    public displayName: string,
    public logoFilename: string,
    public amr: string | null) {
  }
}

export class VerificationCode {
  constructor(public sentTo: string) {
  }
}

export class SignUpRequest {
  constructor(
    public firstAndMiddleName: string,
    public lastName: string,
    public nationalIdentityNumber: string,
    public msisdn: string,
    public signUpId: string,
    public canCancelSignUp: boolean,
    public isBankIdSignUp: boolean
  ) {
  }
}

export class SetupSignUpRequest {
  constructor(
    public signUpId: string,
    public encodedQuery: string,
    public canCancelSignUp: boolean
  ) {
  }
}

export class RedirectResponse {
  constructor(
    public location: string,
    public verificationCodeRequired: boolean,
    public verificationCodeId: string | null) {
  }
}

export class StepUpRequest {
  constructor(
    public signUpId: string,
    public encodedQuery: string,
    public canCancelSignUp: boolean
  ) {
  }
}

export class ApiError {
  constructor(
    public systemKey: string,
    public errorCode: number,
    public i18nPath: string,
    public hasLink: boolean = false
  ) {
  }
}

export interface RequestParameters {
  returnUrl: string,
  redirectUri: string,
  loginHint: string,
  language: string;
  loginContext: string,
  authorizeParams: any
}

export interface Account {
  person?: Person;
  activeProfile: Profile;
  additionalProfiles: Profile;
  email?: string;
  mobile?: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface Profile {
  tnuId?: string;
  legacyProfile?: LegacyProfile;
}

export interface LegacyProfile {
  tssUsername?: string;
  roles: string[];
}

export interface Person {
  firstName?: string;
  middleName?: string;
  lastName?: string;
}

export interface WhoAmIInfo {
  userName: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  mobileNumber: string;
  rnrRoles: string[];
}

export enum LoginState {
  AUTHENTICATED,
  ANONYMOUS
}

export class WhoAmI {
  state: LoginState;
  info: WhoAmIInfo | null;

  constructor(data: LooseObject) {
    this.info = null;
    if (data.info) {
      this.info = <WhoAmIInfo>data.info;
    }
    this.state = data.state == "AUTHENTICATED" ? LoginState.AUTHENTICATED : LoginState.ANONYMOUS;
  }
}

interface LooseObject {
  [key: string]: any
}

export interface AdminAccountDto {
  uuid: string,
  ial: string,
  createdAt: Date,
  updatedAt: Date,
  status: string,
  email?: string,
  mobile?: string,

  person: AdminPersonDto,
  profiles: AdminProfileDto[]
}

export interface AdminPersonDto {
  kurtId?: string,
  kurtIdValidatedAt?: Date,
  createdAt: Date,
  updatedAt: Date,
  firstName?: string,
  middleName?: string,
  lastName?: string,
  dob?: Date
}

export interface AdminProfileDto {
  uuid: string,
  tnuId: string,
  createdAt: Date,
  updatedAt: Date,
  acrLevel: string,
  acr?: string,
  status: string,
  orgKurtId?: string,
  orgKurtIdValidatedAt?: Date,
  legacyTssUsername?: string,
  legacyUsertype?: string,
  legacyRoles?: string,
  legacyStatus?: string,

  externalMappings: AdminExternalMappingDto[],
  attributes: AdminAttributeDto[]
}

export interface AdminExternalMappingDto {
  externalId: string,
  idpUuid: string,
  createdAt: Date,
  updatedAt: Date,
  lastLoginAt?: Date,
  lastLoginFrom?: string
}

export interface AdminAttributeDto {
  uuid: string,
  key: string,
  value: string
}

export class DeviceCodeRequest {
  constructor(
    public clientId: string,
    public scopes: string,
    public identityResources: ScopeInfo[],
    public apiScopes: ScopeInfo[]
  ) {
  }
}

export interface ScopeInfo {
  name: string;
  description: string;
}
