import {ApiError} from "@/types";

export class ErrorService {
  private static _errors: ApiError[] = [
    new ApiError("generic", 50000, "generic.errors.requestError"),
    new ApiError("elvis", 40001, "elvis.errors.wrongUsernamePassword"),

    new ApiError("ibis", 40001, "errors.ninMismatch"),
    new ApiError("ibis", 40002, "ibis.errors.badInput"),
    new ApiError("ibis", 40003, "errors.badOtp"),

    new ApiError("ibis", 40901, "errors.badState"),

    new ApiError("ibis", 42901, "errors.ninLockOut"),
    new ApiError("ibis", 42902, "errors.signUpLocked"),
    new ApiError("ibis", 40401, "errors.expired"),
    new ApiError("ibis", 40402, "errors.expired"),

    new ApiError("ibis", 40801, "errors.tryAgain"),
    new ApiError("ibis", 40802, "errors.contactCustomerService", true),

    new ApiError("kookaburra", 40401, "kookaburra.errors.wrongotp"),
    new ApiError("kookaburra", 40002, "kookaburra.errors.wrongformatotp"),
    new ApiError("kookaburra", 40402, "kookaburra.errors.toomanyotpattempts"),
    new ApiError("kookaburra", 40003, "kookaburra.errors.missingcookie"),
    new ApiError("kookaburra", 40404, "kookaburra.errors.otpnotfound"),
    new ApiError("kookaburra", 40403, "kookaburra.errors.requestnotvalid")
  ];

  public static getGenericError(): ApiError {
    return this._errors[0];
  }

  public static getError(systemKey: string, errorCode: any): ApiError {
    window.console.debug(`Attempting to fetch error ${errorCode} for system ${systemKey}`);

    if (!errorCode) {
      return this._errors[0];
    }

    let error = this
      ._errors
      .find(x => x.errorCode == errorCode && x.systemKey == systemKey);

    if (error) {
      return error;
    }

    window.console.debug("Returning generic error")
    return this._errors[0];
  }
}
