<i18n>
  {
  "no": {
    "nationalIdentityNumber": "Fødselsnummer",
    "willSendNotification": "Vi vil sende en SMS til mobilnummeret tilknyttet fødselsnummeret",
    "11digitNumber": "11-sifret tall",
    "why": "Hvorfor?"
  },
  "en": {
    "nationalIdentityNumber": "National ID Number",
    "willSendNotification": "We will send an SMS to the phone number linked to the national ID number",
    "11digitNumber": "11-digit number",
    "why": "Why?"
  }
  }
</i18n>

<template>
  <div class="validate-number-form">
    <InputText :placeholder="$t('11digitNumber')"
              :label-link="{
                  to: {name: 'ibis-national-id-info'},
                  text: $t('why')
               }"
               :text="$t('nationalIdentityNumber')"
               class=""
               name="nationalIdentityNumber"
               v-model="signUpRequest.nationalIdentityNumber"
               required
               autocomplete="off"
               :info-message="$t('willSendNotification')"
               :validation="validation"
               :validate-on-blur="!signUpRequest.canCancelSignUp"
               :invalid-error="$t('11digitNumber')"
               inputmode="numeric"
    />
    <input type="hidden" name="msisdn" v-model="signUpRequest.msisdn">
    <input type="hidden" name="signUpId" v-model="signUpRequest.signUpId">
    <input type="hidden" name="firstAndMiddleName"
           v-model="signUpRequest.firstAndMiddleName"
    >
    <input type="hidden" name="lastName" v-model="signUpRequest.lastName">
  </div>
</template>

<script>
  import InputText from "../../../telenorComps/Input/InputText";

  export default {
    name: "ValidateNumberForm",
    components: {
        InputText
      },
      props: {
        signUpRequest: Object
      },
    methods: {
      emitFormState(isValid) {
        this.$emit('update:formValid', isValid);
      },
      validation(value){
        let pattern = /^\d{11}$/;
        let isValid = false;
        if (value) {
          isValid = pattern.test(value)
        }

        if(!value && this.signUpRequest.canCancelSignUp) {
          isValid = true;
        }
        
        this.emitFormState(isValid);
        return isValid;
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "../ibis-form-styles.less";
</style>

