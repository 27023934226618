export default {
    hidden: {
        type: Boolean,
        default: false
    },
    id: {
        type: String,
        default: null
    },
    size: {
        type: String,
        default: "m"
    },
    text: {
        type: String,
        default: "Label text"
    },
    labelLink: {
      type: Object,
      default: null
    },
    placeholder: {
        type: String,
        default: ""
    },
    required: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    invalidError: {
        type: String,
        default: null
    },
    emptyError: {
        type: String,
        default: null
    },
    validateOnBlur: {
        type: Boolean,
        default: false
    },
    validation: {
        type: Function
    },
    type: {
        type: String,
        default: "text"
    },
    infoMessage: {
      type: String,
      default: null
    },
    hideDetails: {
        type: Boolean,
        default: false
    },
    highlightValid: {
        type: Boolean,
        default: false
    },
    autocomplete: {
        type: String
    },
    inputmode: {
        type: String
    },
    pattern: {
        type: String
    },
    autofocus: {
        type: Boolean
    },
    name: {
        type: String
    },
    showLabel: {
        type: Boolean,
        default: true
    },
    options: {
        type: Array
    },
    value: {},
    default: {}
};
