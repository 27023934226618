<i18n>
{
  "no": {
    "errorHeading": "En feil har oppstått ved innlogging",
    "errorDescription": "Siden fungerer ikke uten klient sertifikat."
  },
  "en": {
    "errorHeading": "Oh no! We weren't able to log you in",
    "errorDescription": "This page does not work without client certificate."
  }
}
</i18n>
<template>
  <div class="container">
    <section class="info-screen">
      <heading size="m">{{ $i18n.t("errorHeading") }}</heading>
      <div class="error">
        <span style="white-space: pre-line">{{ $i18n.t("errorDescription") }}</span>
      </div>
    </section>
  </div>
</template>

<script>
import Heading from "@/telenorComps/Heading/Heading";

export default {
  name: "MtlsFailed",
  components: {Heading},
  data() {
    return {
      statusCode: this.$route.query.StatusCode,
      errorDescription: this.$route.query.error_description,
      externalParty: this.$route.query.ExternalParty,
      traceId: this.$route.query.TraceId,
      spanId: this.$route.query.SpanId,
      parentId: this.$route.query.ParentId
    }
  },
  methods: {
    hideErrorDetailes() {
      return typeof this.statusCode === 'undefined'
          && typeof this.errorDescription === 'undefined'
          && typeof this.externalParty === 'undefined'
          && typeof this.traceId === 'undefined'
          && typeof this.spanId === 'undefined'
          && typeof this.parentId === 'undefined'
    },
  }
}
</script>

<style lang="less" scoped>
.detailedMtlsFailed {
  font-size: 12px;
}
</style>
