<template>
    <Consent-config/>
</template>

<script>
    import ConsentConfig from "./ConsentConfig";

    export default {
        name: "Consent",
        head(){
            return {
                title: "Consent"
            }
        },
        components: {ConsentConfig},
    }
</script>

