import {ApiError, RedirectResponse, VerificationCode} from "@/types";
import axios from "axios";
import {ErrorService} from "@/error/error.service";

export class VerificationService {

  public async get(codeId: string): Promise<VerificationCode | ApiError> {
    let url = `/verification-code/${codeId}`;

    try {
      let signUpRequest = await axios.get<VerificationCode>(url);
      return signUpRequest.data;

    } catch (e: any) {
      return ErrorService.getError("ibis", e.response.data.errorCode)
    }
  }

  public async validate(codeId: string, oneTimeCode: string): Promise<RedirectResponse | ApiError> {
    let url = `/verification-code/${codeId}/validate`;

    try {
      let signUpRequest = await axios.post<RedirectResponse>(url, {code: oneTimeCode});
      return signUpRequest.data;

    } catch (e: any) {
      return ErrorService.getError("ibis", e.response.data.errorCode)
    }
  }

  public async resend(codeId: string): Promise<void | ApiError> {
    let url = `/verification-code/${codeId}/resend`;

    try {
      await axios.post(url);
    } catch (e: any) {
      return ErrorService.getError("ibis", e.response.data.errorCode)
    }
  }
}
