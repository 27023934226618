import { render, staticRenderFns } from "./Success.vue?vue&type=template&id=27fb0c62&scoped=true&"
import script from "./Success.vue?vue&type=script&lang=js&"
export * from "./Success.vue?vue&type=script&lang=js&"
import style0 from "./Success.vue?vue&type=style&index=0&id=27fb0c62&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27fb0c62",
  null
  
)

/* custom blocks */
import block0 from "./Success.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports