<i18n>
{
  "no": {
    "INVALID_VALUE": "Innholdet er ugyldig",
    "MISSING_VALUE": "Dette feltet er obligatorisk"
  },
  "en": {
    "INVALID_VALUE": "Field value is not valid.",
    "MISSING_VALUE": "Field cannot be empty."
  }
}
</i18n>
<template>
    <Label :labelFor="id" class="input" :class="hidden ? 'hidden' : null">
          <p :class="labelLink ? 'input-text-wrap' : 'input__text'" v-if="showLabel">
            <slot>{{text}}</slot>
            <router-link v-if="labelLink"
                         class="label-link"
                         :to="labelLink.to"
            >
              {{labelLink.text}}
            </router-link>
          </p>
          <input
                :type="type"
                :hidden="hidden"
                :id="id"
                :class="inputClassList"
                :name="name"
                :placeholder="placeholder"
                :disabled="disabled ? 'disabled' : null"
                :value="inputValue"
                :autocomplete="autocomplete"
                :inputmode="inputmode"
                :pattern="pattern"
                :autofocus="autofocus"
                @blur="handleBlur"
                @focus="focusClear"
                @input="handleInput"
        />
        <span class="system-message" v-if="!hideDetails">{{ systemMessage || "&nbsp;" }}</span>
        <span class="info-message" v-if="infoMessage && !systemMessage">{{ infoMessage }}</span>
      <span class="input__validation-icon">
      <errorIcon v-if="valid === false"/>
    </span>
    </Label>
</template>

<script>
    import errorIcon from "@telenor/components-legacy/src/assets/error.svg";
    import commonProps from "./commonProps";

    export default {
        name: "InputBase",
        components: {
            errorIcon
        },
        data() {
            return {
                inputValue: this.value || this.default,
                systemMessage: null
            };
        },
        props: commonProps,
        computed: {
            inputClassList() {
                let classList = [];
                let validation = null;
                if (this.valid === false) validation = "input__error";
                else if (this.inputValue && this.highlightValid && this.valid === true)
                    validation = "input__valid";
                classList.push(validation);
                return classList.length > 0 ? classList : null;
            },
            valid() {
                return this.systemMessage === null;
            }
        },
        watch:{
          value(){
            this.inputValue = this.value
            if (!this.validateOnBlur)
              this.validate();
          }
        },
        methods: {
            clearSystemMessage() {
                this.systemMessage = null;
            },
            handleInput(event) {
                this.inputValue = event.target.value;
                if (!this.validateOnBlur)
                    this.validate(this.inputValue);
                this.$emit('input', this.inputValue);
            },
            handleBlur() {
                if (this.validateOnBlur)
                    this.validate();
                this.$emit('input', this.inputValue);
            },
            focusClear() {
                if (this.validateOnBlur)
                    this.clearSystemMessage();
            },
            validate() {
                if ((!this.inputValue || this.inputValue === "") && this.required) {
                    this.systemMessage = this.emptyError || this.$t('MISSING_VALUE');
                } else if (this.validation && !this.validation(this.inputValue)) {
                    this.systemMessage = this.invalidError || this.$t('INVALID_VALUE');
                } else {
                    this.clearSystemMessage();
                }
            }
        }
    };
</script>

<style lang="less" scoped>
    @import "~@telenor/components-legacy/node_modules/@telenor/tokens/build/less/tokens.less";
    @import (reference) "~@telenor/tokens/build/less/variables.less";
    @import "~@/assets/variables.less";

    .hidden {
        display: none;
    }

    .system-message, .info-message {
        display: inline-block;
        font-size: @size-font-desktop-xs;
        margin-top: @spacing-s;
        margin-bottom: 10px;
    }
    .system-message {
      color: @critical-800-shade;
      font-family: @asset-font-telenor-light-name;
    }
    .info-message {
      color: @color-base-gray-dark;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    input {
        position: relative;
        margin: 0;

        &[type="text"],
        &[type="email"],
        &[type="tel"],
        &[type="url"],
        &[type="password"],
        &[type="date"] {
            width: 100%;
            padding: @spacing-s @spacing-none;
            font-size: @size-font-desktop-medium;
            border: none;
            border-bottom: 1px solid @color-base-gray-light;
            caret-color: @color-base-telenor-blue;

            &:focus {
                outline: none;
                border-bottom: 1px solid @color-base-telenor-blue;
            }
        }

        &[type="file"] {
            display: block;
            font-size: @size-font-desktop-small;
            border-bottom: none !important;
        }
    }

    .input {
        &-text-wrap {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          font-size: @size-font-desktop-xs;
          margin-bottom: @spacing-xs;


          .label-link {
            color: @color-base-telenor-link;
            text-decoration: none;
            margin: 0px;
            &:hover{
              text-decoration: underline;
            }
          }
        }

      &__text {
        font-size: .8125rem;
        margin-top: @spacing-lg;
        margin-bottom: @spacing-xs;
      }
      &__validation-icon {
        display: block;
        width: 16px;
        height: 16px;
        float: right;
        position: relative;
        top: -26px;

        & svg {
          width: 100%;
          height: 100%;
        }
      }
        &__error {
            border-bottom: 1px solid @color-information-red !important;
        }

        &__valid {
            border-bottom: 1px solid @color-information-green !important;
        }
    }

    .disabled {
        color: @color-base-gray-light;

        input {
            pointer-events: none;

            &[type="text"],
            &[type="email"],
            &[type="tel"],
            &[type="url"],
            &[type="password"],
            &[type="date"] {
                border-bottom: 1px solid @color-base-gray-light;
                caret-color: transparent;

                &:focus {
                    border-bottom: 1px solid @color-base-gray-light;
                }
            }
        }
    }
</style>

