<i18n>
{
    "no": {
      "header": "Identifisering",
      "expired": "Økten har utløpt. Lukk nettleser og forsøk igjen."
    },
    "en": {
      "header": "Identification",
      "expired": "This session has expired. Please close this browser window and try again."
    }
}
</i18n>

<template>
  <form-wrapper>
    <heading size="l" class="heading">
      {{ $t('header') }} 
    </heading>
    <div class="error" >
      <span>{{ $t('expired') }}</span>
    </div>
  </form-wrapper>
</template>

<script>
import Heading from "@/telenorComps/Heading/Heading";
import FormWrapper from "@/components/FormWrapper";

export default {
    name: "Validate",
    components: { 
      FormWrapper,
      Heading
    },
    data() {
        return {
          signUpRequestId: "",
          errorCode: "",
          signUpResponse: ""
        }
    },
    created() {
        this.signUpRequestId = this.$route.query.request;
        this.errorCode = this.$route.query.error;
        this.signUpResponse = this.$route.query.response;
    },
    async mounted() {
        if(this.signUpResponse) {
          window.location = "/external/callback";
        }

        if(this.signUpRequestId && this.errorCode) {
          return this.$router.push({
          name: 'ibis-sign-up-phone',
          query: {request: this.signUpRequestId, error: this.errorCode}
        });
      }
    }
}
</script>
<style lang="less" scoped>
@import "../ibis-form-styles.less";

.heading {
  margin-bottom: @spacing-sm;
}
</style>