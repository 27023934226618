<i18n>
{
  "en": {
    "otp": {
      "sms": {
        "lead": "Could not deliver verification code by sms."
      },
      "ivr": {
        "lead": "Could not deliver verification code by phone call."
      }
    },
    "solution": "Please go back to the service you tried to use.",
    "solution-fallback": "SMS sending failed. You can receive the verification code from a phone call.",
    "ivr-fallback": "Get verification code by phone call."
  },
  "no": {
    "otp": {
      "sms": {
        "lead": "Kunne ikke levere bekreftelseskode med sms."
      },
      "ivr": {
        "lead": "Kunne ikke levere bekreftelseskode ved oppringing."
      }
    },
    "solution": "Gå tilbake til tjenesten du kom fra for å prøve på nytt eller andre alternativer.",
    "solution-fallback": "SMS utsending feilet. Du kan bli oppringt for å få bekreftelseskoden.",
    "ivr-fallback": "Bli oppringt for bekreftelseskoden."
  }
}

</i18n>
<template>
  <form-wrapper>

    <div class="error">
      {{ $t('otp.' + ivrOrSms + '.lead') }}
    </div>

    <heading size="m" class="heading" v-if="!fallbackAllowed">
      {{ $t('solution') }}
    </heading>

    <heading size="m" class="heading" v-if="fallbackAllowed">
      {{ $t('solution-fallback') }}
    </heading>

    <div class="input-fields" v-if="fallbackAllowed">
      <button @click="triggerFallback" class="button button-primary iconbutton">
        {{$i18n.t('ivr-fallback') }}
      </button>
    </div>

    <div class="bottom-link" v-if="!isTest">
      <t-link href="https://mbn.telenor.no/start">https://mbn.telenor.no/start</t-link>
    </div>
    <div class="bottom-link" v-if="isTest">
      <t-link href="https://mbn-at.telenor.no/start">https://mbn-at.telenor.no/start</t-link>
    </div>

  </form-wrapper>
</template>

<script>
import FormWrapper from "../../components/FormWrapper";
import {
  Heading,
  Link
} from "@telenor/components-legacy/src/components";
import { fallbackLogin } from "@/views/kookaburra/api";

import { ErrorService } from "@/error/error.service";

export default {
  name: "index.vue",
  components: {
    FormWrapper,
    Heading,
    TLink: Link
  },
  computed: {
    isTest() {
      return location.hostname.includes("test");
    },
    ivrOrSms() {
      return this.$route.query.ivr === "true" ? "ivr" : "sms"
    },
    loginUrl() {
      return process.env.VUE_APP_KOOKABURRA_BASE_URL + '/oauth/login';
    },
    phoneNumber() {
      return this.$route.query.phoneNumber
    },
    error() {
      let err = this.$route.query.error
      if (err) {
        let msg = ErrorService.getError("kookaburra", err)
        return { message: msg, code: err }
      } else {
        return null
      }
    },
    fallbackAllowed() {
      if (this.$route.query.ivr !== "true" && this.$route.query.attemptFallback === "true") {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    triggerFallback() {
      fallbackLogin().catch(err => {
        //handle it...
      })
    }
  }
}


</script>

<style lang="less" scoped>
@import (reference) "~@telenor/tokens/build/less/variables.less";
@import "../ibis/ibis-form-styles.less";
@import "~@telenor/components-legacy/node_modules/@telenor/tokens/build/less/tokens.less";

.button-icon {
  width: 18px;
  height: 18px;
  margin-bottom: -3px;
}

.iconbutton {
  background-image: url("../../assets/telenor-logo-flat-white.svg");
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: 4% center;
}

.button {
  background-color: @color-base-telenor-link;
  color: @color-base-white;

    &:hover {
      background-color: darken(@color-base-telenor-link, 15%);
    }

    & .button__chevron path {
      fill: @color-base-white;
    }
}

::v-deep .input-fields {
  min-width: 400px;

  input[type=submit] {
    margin: 20px auto 0;
    display: block;
  }
}

.heading {
  margin: 50px auto;
  display: block;
  text-align: center;
}

@media screen and (max-width: 768px) {
  ::v-deep .input-fields {
    min-width: 0;
    width: 100%;

    form {
      label+label {
        margin-top: 30px;
      }
    }
  }
}

.top-space {
  margin-top: 20px;
}

.bottom-link {
  text-align: center;
  margin: 20px 0 0;
}

.text {
  margin-bottom: 50px;
  font-family: "Source Sans Pro", sans-serif;
}

.error {
  padding: 10px 15px;
  font-family: @asset-font-telenor-name;
  font-size: 14px;
  background: @color-information-critical-normal;
  border-radius: 7px;
  margin-bottom: 50px;
  text-align: center;
}

.input__text {
  margin-bottom: @spacing-sm !important;
}

.code-label {
  margin: 0px auto;
  text-align: center;

  .input-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    ::v-deep .code-input {
      width: 150px;
      margin: 0px;
      padding: @spacing-md @spacing-lg;
      border-radius: 5px 0px 0px 5px;

      &:focus {
        //padding: calc(@spacing-md - 1px ) calc(@spacing-lg - 1px );
        border: 1px solid @color-telenor-link;
      }
    }

    .code-button {
      display: inline-block;
      width: 8px;
      height: 25px;
      padding: @spacing-md @spacing-lg;
      border-radius: 0px 5px 5px 0px;
      margin: 0px;
      background-color: @color-telenor-link;
      outline: none;
      border: none;
      position: relative;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: @color-telenor-link-hover;
      }

      &::before,
      &::after {
        content: "";
        display: block;
        border-top: 2px solid @color-white;
        position: absolute;
        top: 50%;
        left: 50%;
      }

      &::before {
        width: 10px;
        height: 10px;
        transform: translate(-30%, -50%) rotate(45deg);
        border-right: 2px solid @color-white;
      }

      &::after {
        width: 20px;
        transform: translate(-58%, -50%);
      }
    }
  }
}
</style>
