<template>
    <span>{{phoneNumber}}</span>
</template>

<script>
    export default {
        name: 'Msisdn',
        props: ['number'],
        computed: {
            phoneNumber() {
                if (!this.number) {
                    return '';
                }

                let number = this.number;
                return `(${number.substring(0, 3)}) ${number.substring(3)}`;
            },
        }
    }
</script>
