<i18n>
{
  "no": {
    "errors": {
      "expired": "Økten har utløpt. Lukk nettleser og forsøk igjen.",
      "ninMismatch": "Vi fant deg ikke i folkeregisteret. Kontakt kundeservice.",
      "signUpLocked": "Noe gikk galt. Lukk nettleser og prøv igjen.",
      "invalidForm": "Alle påkrevde felter må fylles ut.",
      "badState": "Vi kunne ikke fullføre registrering av konto. Vennligst kontakt kundeservice for videre hjelp.",
      "tryAgain": "Vennligst prøv på nytt",
      "contactCustomerService": "Kontakt kundeservice"
    },
    "submit": "Bekreft identitet",
    "continueWithoutSignUp": "Fortsett uten fødselsnummer",
    "kyc": {
      "header": "Bekreft din identitet"
    },
    "description": "I henhold til norsk lov må vi verifisere din identitet når du bruker våre tjenester. Om du f.eks. gjør en endring på et abonnement eller handler i nettbutikken, skal du være trygg på at sikkerheten din blir ivaretatt."
  },
  "en": {
    "errors": {
      "expired": "This session has expired. Please close this browser window and try again.",
      "ninMismatch": "We can't find you in the National Registry. Please contact customer service.",
      "signUpLocked": "Something went wrong. Please close this browser window and try again.",
      "invalidForm": "All mandatory fields have to be filled.",
      "badState": "We are unable to complete registration of this account. Please contact customer service.",
      "tryAgain": "Please try again",
      "contactCustomerService": "Please contact customer service"
    },
    "submit": "Confirm identity",
    "continueWithoutSignUp": "Continue without national ID Number",
    "kyc": {
      "header": "Verify your identity"
    },
    "description": "According to Norwegian law we need to verify your identity when you are using our services. If you are doing any changes to your subscription or shopping in the online store, you should be confident that your safety is taken care of."
  }
}
</i18n>

<template>
  <div class="container">
    <form-wrapper>
      <heading size="l" class="heading">
        {{ $t('kyc.header') }} 
      </heading>
      <div class="error" v-if="error">
        <div v-if="errorLink">
          <a v-bind:href="errorLink">{{ $t(error) }}</a>
        </div>
        <div v-else>
          <span>{{ $t(error) }}</span> 
        </div>
       
      </div>
      <div v-if="isValidIdps">
        <idp v-for="idp in idps" :key="idp.displayName" :idp="idp" :url="getIdpUrl(idp)"/>
      </div>
      <div class="heading">
        {{ $t("description") }}
      </div>
    </form-wrapper>
  </div>
</template>
<script>
import FormWrapper from "@/components/FormWrapper";
import {StepUpService} from "./stepup.service"
import Heading from "@/telenorComps/Heading/Heading";
import {EnvironmentService} from "@/environment";
import {IdpService} from '@/views/ibis/login/idp.service';
import Idp from "@/components/Idp";
import {ErrorService} from "@/error/error.service"
import {RequestParsingService} from "@/views/ibis/requestParsing.service";

export default {
  name: "StepUp",
  components: {
    FormWrapper,
    Heading,
    Idp
  },
  data() {
    return {
      stepUpRequestId: "",
      stepUpRequest: {},
      setupStepUpRequest: {},
      formValid: false,
      error: null,
      isLoading: false,
      environment: {},
      idps: [],
      loginContext: '',
      isValidIdps: false,
      request: null,
      errorLink: ''
    }
  },
  methods: {
    handleError(error) {
      this.error = error.i18nPath;
      if(error.hasLink) {
        let lang = this.$i18n.locale;
        this.errorLink = this.environment.ksUrlEn
        if (lang === 'no') {
          this.errorLink = this.environment.ksUrlNo
        }
      }
    },
    async setupBankIdStepUp(){
      let setupStepUpRequest = await this.stepUpService.getStepUpRequest(this.stepUpRequestId);
      
      this.loginContext = 'kyc';
      this.idps = new IdpService().getIdps(['no.bankid.vipps']);

      if (this.errorCode && (setupStepUpRequest.errorCode === 50000 || !setupStepUpRequest.errorCode)) {
        let error = ErrorService.getError('ibis', this.errorCode);
        this.handleError(error);
        return;
      }

      if (setupStepUpRequest.errorCode) {
        this.handleError(setupStepUpRequest);
        return;
      }
      this.setupStepUpRequest = setupStepUpRequest;
      this.request =  RequestParsingService.parseUrlEncodedJson(this.setupStepUpRequest.encodedQuery) || {};
      
      if (this.setupStepUpRequest.encodedQuery) {
        this.isValidIdps = true;
      }

    },
    getIdpUrl(idp) {
      let idpRequest = Object.assign({}, this.request);
      idpRequest.authScheme = idp.authScheme;
      idpRequest.idps = null;

      let stepUpRequest = this.setupStepUpRequest;

      if (!idpRequest.authorizeParams) {
        idpRequest.authorizeParams = {};
      }

      if (idp.amr) {
        window.console.debug(`Setting AMR to ${idp.amr}`)
        idpRequest.authorizeParams.amr = idp.amr;
      }

      let encoded = RequestParsingService.toUrlEncodedJson(idpRequest);
      return `/step-up/challenge?requestUrlEncoded=${encoded}`
    }
  },
  created() {
    this.stepUpService = new StepUpService();
    this.stepUpRequestId = this.$route.query.request;
    this.errorCode =  this.$route.query.error;
  },
  async mounted() {
    this.environment = await EnvironmentService.getEnvironment();
    this.setupBankIdStepUp();
    return;
  }
}
</script>
<style lang="less" scoped>
@import "../ibis-form-styles.less";

.heading {
  margin-bottom: @spacing-sm;
}

.button.cancel,
.cancel-signup {
  margin-top: @spacing-sm;
}

.link {
  display: block;
  text-align: center;
}
</style>
