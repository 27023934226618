<i18n>
{
  "no": {
    "doYouWantToLogOut": "Vil du logge ut?",
    "youWillBeRedirected": "Du vil bli omdirigert til {0}",
    "logOut": "Logg ut"
  },
  "en": {
    "doYouWantToLogOut": "Do you want to log out?",
    "youWillBeRedirected": "You will be redirected to {0}",
    "logOut": "Log out"
  }
}
</i18n>
<template>
  <form-wrapper class="info-screen">
    <heading size="l">{{$t("doYouWantToLogOut")}}</heading>
    <p>{{$t("youWillBeRedirected", {0: redirectTitle})}}</p>
    <a class="button confirm-cancel" href="/account/logout" target="_blank">
      {{ $t('logOut') }}
    </a>
<!--    <img class="logo" src="@/assets/telenor-logo-flat-blue.svg" alt="telenor logo blue"/>-->
  </form-wrapper>
</template>
<script>
import FormWrapper from "@/components/FormWrapper";
import {Heading} from "@telenor/components-legacy/src/components";

export default {
  name: "Logout",
  components: {
    FormWrapper,
    Heading
  },
  computed: {
    redirectTitle(){
      let title = "Title/Url here";
      return title;
    }
  }
}
</script>

<style lang="less" scoped>
  @import "./ibis-form-styles.less";
  .heading {
    text-align: left;
  }
</style>
