import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  "en": {
    generic: {
      loading: "Just a sec ...",
      errors: {
        requestError: "We're having trouble completing your request right now"
      },
      logIn: "Log in"
    },
    ibis: {
      errors: {
        badInput: "Ops. Your input was not accepted"
      }
    },
    fields: {
      username: "User name",
      password: "Password",
      passwordRepeat: "Repeat password",
      firstName: "First name",
      middleName: "Middle name",
      lastName: "Last name",
      birthday: "Birthday",
      zipCode: "Postal code",
      phone: "Phone number",
      email: "Email"
    },
    "BAD_PASSWORD": {
      "TOO_SHORT": "The password is too short",
      "TOO_LONG": "The password is too long",
      "TOO_MANY_KEYBOARD_NEIGHBOURS": "The password has too many keyboard neighbours",
      "TOO_MANY_CHARACTER_NEIGHBOURS": "The password has too many alphabetical neighbours",
      "CONTAINS_PHONE_NUMBER": "The password contains a phone number",
      "IS_A_DATE": "The password is a date",
      "IS_BLOCKED": "The password is to common and is blocked"
    },
    "NOT_EQUAL": "The passwords must be equal",
    "INVALID_PHONE": {
      "FORMAT": "Invalid phone number",
      "NOT_ELIGIBLE": "Phone number not eligible for signup"
    },
    "USER_NOT_FOUND": "User account not found",
    "LOCKED" : "User account is temporarily locked",
    "UNATHORIZED" : {
      "INVALID_USERNAME_OR_PASSWORD": "Invalid username or password"
    },
    "INVALID_USERNAME_OR_PASSWORD": "Invalid username or password",
    "WRONG_USERNAME_OR_PASSWORD" : "Wrong username or reset code",
    "MISSING_OTP" : "One time code cannot be empty",
    "WRONG_OTP" : "Wrong one time code",
    "MISSING_USERNAME" : "Username cannot be empty",
    "USERNAME_ALREADY_TAKEN" : "Username already taken",
    "BAD_REQUEST": {
      "INVALID_DATE_OF_BIRTH": "Missing or invalid date of birth",
      "INVALID_EMAIL": "Missing or invalid email address",
      "INVALID_FIRST_NAME": "Missing or invalid first name",
      "INVALID_LAST_NAME": "Missing or invalid last name",
      "INVALID_MIDDLE_NAME": "Missing or invalid middle name",
      "INVALID_OTP": "Missing or invalid one time password/code",
      "INVALID_PASSWORD": "Missing or invalid password",
      "INVALID_PASSWORD_RESET_CODE": "Missing or invalid password reset code",
      "INVALID_PHONE": "Missing or invalid phone number",
      "INVALID_USERNAME": "Missing or invalid user name",
      "INVALID_ZIP_CODE": "Missing or invalid zip code",
    }
  },
  "no": {
    generic: {
      loading: "Et lite øyeblikk ...",
      errors: {
        requestError: "Noe gikk galt, prøv igjen senere."
      },
      logIn: "Logg inn"
    },
    ibis: {
      errors: {
        badInput: "Auda. Vennligst sjekk at du skrev inn alt riktig"
      }
    },
    fields: {
      username: "Brukernavn",
      password: "Passord",
      passwordRepeat: "Gjenta passordet",
      firstName: "Fornavn",
      middleName: "Mellomnavn",
      lastName: "Etternavn",
      birthday: "Fødselsdato",
      zipCode: "Postnummer",
      phone: "Mobilnummer",
      email: "Epostadresse"
    },
    "BAD_PASSWORD": {
      "TOO_SHORT": "Passordet er for kort",
      "TOO_LONG": "Passordet er for langt",
      "TOO_MANY_KEYBOARD_NEIGHBOURS": "Passordet har for mange tegn ved siden av hverandre på tastaturet",
      "TOO_MANY_CHARACTER_NEIGHBOURS": "Passordet har for mange alfabetiske naboer",
      "CONTAINS_PHONE_NUMBER": "Passordet inneholder telefonnummeret",
      "IS_A_DATE": "Passordet er en dato",
      "IS_BLOCKED": "Passordet er for vanlig og er blokkert"
    },
    "NOT_EQUAL": "Passordene må være like",
    "INVALID_PHONE": {
      "FORMAT": "Ugyldig telefonnummer",
      "NOT_ELIGIBLE": "Dette telefonnummeret kan ikke brukes her"
    },
    "USER_NOT_FOUND": "Brukerkonto finnes ikke",
    "LOCKED" : "Brukerkontoen er midlertidig låst",
    "UNATHORIZED" : {
      "INVALID_USERNAME_OR_PASSWORD": "Ugyldig brukernavn eller passord"
    },
    "WRONG_USERNAME_OR_PASSWORD": "Ugyldig brukernavn eller passord",
    "WRONG_USERNAME_OR_RESET_CODE" : "Feil brukernavn eller engangskode",
    "MISSING_OTP" : "Engangskode mangler",
    "WRONG_OTP" : "Feil engangskode",
    "MISSING_USERNAME" : "Brukernavnet mangler",
    "USERNAME_ALREADY_TAKEN" : "Brukernavnet er opptatt",
    "BAD_REQUEST": {
      "INVALID_DATE_OF_BIRTH": "Manglende eller ugyldig fødselsdato",
      "INVALID_EMAIL": "Manglende eller ugyldig epostadresse",
      "INVALID_FIRST_NAME": "Manglende eller ugyldig fornavn",
      "INVALID_LAST_NAME": "Manglende eller ugyldig etternavn",
      "INVALID_MIDDLE_NAME": "Manglende eller ugyldig mellomnavn",
      "INVALID_OTP": "Ugyldig bekreftelseskode",
      "INVALID_PASSWORD": "Manglende eller ugyldig passord",
      "INVALID_PASSWORD_RESET_CODE": "Manglende eller ugyldig engangskode",
      "INVALID_PHONE": "Manglende eller ugyldig telefonnummer",
      "INVALID_USERNAME": "Manglende eller ugyldig brukernavn",
      "INVALID_ZIP_CODE": "Manglende eller ugyldig postnummer",
    }
  }
};

export const i18n = new VueI18n({
  locale: "no",
  fallbackLocale: "en",
  messages
});
