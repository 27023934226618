<i18n>
{
  "no": {
    "ACCOUNT_MISSING_EMAIL": "Kontoen mangler epostadresse",
    "ACCOUNT_MISSING_MOBILE": "Kontoen mangler mobilnummer",
    "TITLE" : "Glemt brukernavn",
    "SEND_IT": "Send meg brukernavnet",
    "SUCCESS": "Brukernavnet er sent til kontaktinformasjonen du oppga. Gå tilbake til",
    "LOGIN": "innlogging"
  },
  "en": {
    "ACCOUNT_MISSING_EMAIL": "User account has no email address",
    "ACCOUNT_MISSING_MOBILE": "User account has no mobile number",
    "TITLE" : "Forgot user name",
    "SEND_IT": "Send me the username",
    "SUCCESS": "The user name was sent. Go back to ",
    "LOGIN": "login"
  }
}
</i18n>
<template>
  <div class="container">
    <form-wrapper>
      <heading size="xl" class="heading">{{ $t('TITLE') }}</heading>
      <ErrorMessage :error="error"/>
      <div class="text" v-html="text" v-if="text"></div>
      <div class="input-fields" v-if="state == 'input'" key="one">
        <form @submit="onSubmit" method="post">
          <input-text name="firstName" :text="$t('fields.firstName')" :placeholder="$t('fields.firstName')"
                      :required=true validate-on-blur v-model="model.firstName"/>
          <input-text name="middleName" :text="$t('fields.middleName')" :placeholder="$t('fields.middleName')"
                      :required=false validate-on-blur v-model="model.middleName"/>
          <input-text name="lastName" :text="$t('fields.lastName')" :placeholder="$t('fields.lastName')" :required=true
                      validate-on-blur v-model="model.lastName"/>
          <input-text name="birthday" :text="$t('fields.birthday')" placeholder="2000-04-27" :required=true
                      validate-on-blur v-model="model.birthday"/>
          <input-text name="zipCode" :text="$t('fields.zipCode')" placeholder="1234" :required=true validate-on-blur
                      v-model="model.zipCode"/>
          <input-text name="phone" :text="$t('fields.phone')" placeholder="+4755555555" validate-on-blur :required=false
                      v-model="model.mobile"/>
          <input-text name="email" :text="$t('fields.email')" placeholder="oda@nordmann.no" validate-on-blur
                      :required=false v-model="model.email"/>
          <div class="buttons">
            <t-button comp-type="submit-button" :value="$t('SEND_IT')" size="large" class="login-button"></t-button>
          </div>
        </form>
      </div>
      <div v-if="state=='sent'" key="two">
        <p class="description">{{ $t('SUCCESS') }}
          <router-link class="link" :to="{ name: 'elv-login', params: { query: request }}">{{ $t('LOGIN') }}
          </router-link>
        </p>
      </div>
    </form-wrapper>
  </div>
</template>

<script>
  import {
      Button,
      Heading
  } from "@telenor/components-legacy/src/components";
  import FormWrapper from "@/components/FormWrapper";
  import { forgotUsername } from "../api";
  import InputText from "@/telenorComps/Input/InputText";
  import ErrorMessage from "@/views/elvis/components/errorMessage";


  export default {
    name: "ElvisForgotPassword",
    components: {
        FormWrapper,
        TButton: Button,
        Heading,
        InputText,
        ErrorMessage
    },
    computed: {
      request() {
        return this.$route.query['request'] || "";
      },
      action() {
        return env.VUE_APP_ELVIS_BASE_URL + "/login/forgotten-username";
      }
    },
    data() {
      return {
        text: null,
        model: {
          firstName: "",
          middleName: "",
          lastName: "",
          birthday: "",
          zipCode: "",
          mobile: "",
          email: "",
        },
        error: null,
        state: "input"
      }
    },
    methods: {
      onSubmit: function(e) {
        e.preventDefault();
        let dto = Object.assign({}, this.model);
        forgotUsername(dto).then(() => {
          this.state = 'sent'
        }).catch(err => this.error = err);
      }
    }
  }
</script>


