<template>
  <div>
    <div class="idp">
      <img alt="Idp Logo" :src="`${publicPath + idp.logoFilename}`" class="logo">
      <a :href="`${url}`">{{idp.displayName}}</a>
      <img src="../assets/chevron_right.svg" alt="Chevron" class="chevron_right"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Idp.vue",
    props: ["idp", "url"],
    data() {
      return {
        publicPath: process.env.BASE_URL + '/img/'
      }
    }
  }
</script>

<style lang="less" scoped>
  .idp {
    width: 100%;
    height: 6em;
    margin: 1em 0;
    background: #f5f5f5;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    img.logo {
      max-height: 30px;
      flex: 1 1 0;
      margin: 0.5rem;
      padding-left: 1em;
    }

    img.chevron_right {
      flex: 1 1 0;
      height: 20%;
    }

    a {
      font-size: 1.2rem;
      color: #000;
      text-decoration: none;
      flex: 3 1 0;
    }
  }
</style>
