<template>
    <debugger-config/>
</template>

<script>
    import DebuggerConfig from "../../components/DebuggerConfig";

    export default {
        name: "Debugger",
        head(){
            return {
                title: "Debugger"
            }
        },
        components: {DebuggerConfig},
    }
</script>

