import {DeviceCodeRequest, RedirectResponse, ApiError} from "@/types";
import axios from "axios";
import {ErrorService} from "@/error/error.service";

export class DeviceFlowService {

  public async getDeviceRequest(userCode: string | null): Promise<DeviceCodeRequest | ApiError> {
    let url = `/device/setup/${userCode}`;

    try {
      let infoRequest = await axios.get<DeviceCodeRequest>(url);
      return infoRequest.data;

    } catch (e: any) {
      return ErrorService.getError("ibis", e.response.data.errorCode)
    }
  }
}
