<i18n>
{
  "no": {
    "title": "Samtykke",
    "next": "Tillat samtykke",
    "nextCancel": "Avbryt",
    "infoMessage": "Følgende tillatelser blir bedt om av appen og ditt samtykke kreves for å gi den tilgang.",
    "cancelText": "Hvis du ikke godkjenner, klikker du avbryt og ingen informasjon vil bli delt av appen.",
    "accessText": "ønsker å få tilgang til kontoen din.",
    "maintenance": {
      "header": "Vedlikehold",
      "text": "Innlogging vil være utilgjengelig mens vi jobber med vedlikehold på våre digitale tjenester. Vi beklager eventuelle ulemper dette måtte medføre"
    }
  },
  "en": {
    "title": "Consent",
    "next": "Allow Consent",
    "nextCancel": "Cancel",
    "infoMessage": "The following permissions are requested by the app and your consent is required to provide it access.",
    "cancelText": "If you do not approve, click cancel and no information will be shared by the app.",
    "accessText": "wants to access your account.",
    "maintenance": {
      "header": "Maintenance",
      "text": "Login will be unavailable while we work on maintenance of our digital services. We apologize for any inconvenience this may cause"
    }
  }
}
</i18n>
<template>
  <div class="container" v-if="this.environment.maintenance !== 'true'">
    <form-wrapper>
    <div v-if="user">
      <heading size="m" class="heading"> {{$i18n.t('title')}} </heading>

    <div class="info-message align"><span class="clientText">{{this.clientId}}</span> {{$i18n.t('accessText')}}
    <br><br>
      {{$i18n.t('infoMessage')}}
    </div>
    <br><br>

    <ul id="scopeList">
  <li v-for="obj in this.identityResources" :key="obj.name">
    <div class="userText"> {{ obj.name }} </div> <div class="info-message">- {{ obj.description }}</div> <br>
  </li>
  <li v-for="obj in this.apiScopes" :key="obj.name">
    <br> <div class="userText"> {{ obj.name }} </div> <div class="info-message">- {{ obj.description }}</div>
  </li>
</ul>

  <br>

        <form action="/device/consent" method="get">
          
          <t-button class="button login-button button-primary iconbutton"
                    compType="submit-button"
                    :value="$i18n.t('next')"
                    id="btnGo"
          />
          <input type="hidden" name="userCode" :value="userCode" v-if="userCode"/>
                  </form>
          <br>

        <form action="/device/cancel" method="get">

          <t-button class="button logout-button button-primary iconbutton" style="background:grey;"
                    compType="submit-button"
                    :value="$i18n.t('nextCancel')"
                    id="btnGo"
          />

          <input type="hidden" name="userCode" :value="userCode" v-if="userCode"/>
          </form>

    <br>
  <div class="info-message" style="text-align:center">
    {{$i18n.t('cancelText')}}
  </div>     
</div>

<div v-else class="info-message" style="text-align:center">
Loading...
</div>
    </form-wrapper>
  </div>
  <div class="container" v-else>
    <heading size="l" class="heading">
      {{ $t('maintenance.header') }} 
    </heading>
      <span>{{ $t('maintenance.text') }}</span>
  </div>
</template>

<script>
  import ModelSpecForm from "@/components/ModelSpecForm";
  import {RequestParsingService} from "@/views/ibis/requestParsing.service";
  import {ErrorService} from "@/error/error.service";
  import {DeviceFlowService} from "./device-flow.service";
  import {UserManager, WebStorageStateStore} from 'oidc-client';
  import {EnvironmentService} from "@/environment";
  import {Button} from "@telenor/components-legacy/src/components";
  import FormWrapper from "@/components/FormWrapper";
  import Heading from "@/telenorComps/Heading/Heading";
  import InputText from "@/telenorComps/Input/InputText";
  import JsonViewer from 'vue-json-viewer/ssr.js';
  import UrlForm from "@/components/UrlForm";

  export default {
    name: "ConsentConfig",
    components: {
      FormWrapper,
      Heading,
      TButton: Button
    },
    created(){
      this.deviceFlowService = new DeviceFlowService();
    },
    async mounted() {
      this.environment = await EnvironmentService.getEnvironment();
      let storedAuthority = localStorage.getItem('authority');
            if (storedAuthority)
                this.environment = storedAuthority;
           

            if (this.$route.query.state && this.$route.query.code) { // Callback
                let mgr = this.getUserManager();
                await mgr.signinRedirectCallback().then(user => {
                    this.user = user;
                    this.userCode = user.state;
                    window.console.log(user);
                }).catch(err => {
                    window.console.error(err);
                });

                let response = await this.deviceFlowService.getDeviceRequest(this.userCode);
                this.clientId = response.clientId;
                this.scopes = response.scopes;
                this.identityResources = response.identityResources;
                this.apiScopes = response.apiScopes;

            } else {
                window.console.error("Missing state param/code in return url?!");
            }
        },

    data() {
      return {
        environment: {},
        user: null,
        userCode: null,
        scopes: "",
        identityResources: [],
        apiScopes: [],
        clientId: null

      }
    },
    methods: {
      getUserManager() {
                return new UserManager({
                    response_mode: 'query',
                    loadUserInfo: true,
                    userStore: new WebStorageStateStore({store: window.localStorage})
                });
            }
    },
    computed: {
    }
  }
</script>

<style lang="less" scoped>
 @import "../ibis-form-styles.less";
 @import "~@telenor/components-legacy/node_modules/@telenor/tokens/build/less/tokens.less";

 .button-icon {
   width: 18px;
   height: 18px;
   margin-bottom: -3px;
 }
 .iconbutton {
   background-image: url("../../../assets/telenor-logo-flat-white.svg");
   background-repeat: no-repeat;
   background-size: 18px 18px;
   background-position: 10% center;
 }
 .info-message.align{
   text-align: center;
 }
 .userText {
   color: black;
   font-size: 20px;
   font-family: @asset-font-telenor-light-name;
 }
  .clientText {
   color: black;
   font-size: 16px;
   font-family: @asset-font-telenor-light-name;
 }
</style>