import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {i18n} from './plugins/i18n'

Vue.config.productionTip = false;

export function setI18nLanguage (lang: string) {
  i18n.locale = lang;
  // @ts-ignore
  window.document.querySelector('html').setAttribute('lang', lang);
  return lang;
}

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app');


