<i18n>
{
  "no": {
    "title": "Connect Device",
    "userCode": "Bekreftelseskode (9 siffer)",
    "infoMessage": "Skriv in koden som visas på din enhet. Fortsett bare hvis di øverenstemmer"
  },
  "en": {
    "title": "Connect Device",
    "userCode": "Verification code (9 digits)",
    "infoMessage": "Enter the code displayed on your device"
  }
}
</i18n>

<template>
  <div class="container" v-if="this.environment.maintenance !== 'true'">
    <form-wrapper>
      <heading size="m" class="heading"> {{$i18n.t('title')}} </heading>

<div class="input-fields top-space">
      <form action="/device/activate" method="get">

        <div class="verify-person-form">
          <label class="input code-label">
            <p class="input__text">{{$t('userCode')}}</p>
            
            <div class="input-wrap">
              <input type="text"
                     maxlength="9"
                     placeholder=""
                     class="code-input"
                     name="userCode"
                     v-model="userCode"
                     autocomplete="one-time-code"
                     pattern="[0-9]*"
                     inputmode="numeric"
              />
              <button type="submit" class="code-button" :disabled="disabledButton" />
              
            </div>
            <p class="info-message">{{$t('infoMessage')}}</p>
          </label>
        </div>
      </form>
    </div>


    </form-wrapper>
  </div>
  <div class="container" v-else>
    <heading size="l" class="heading">
      {{ $t('maintenance.header') }} 
    </heading>
      <span>{{ $t('maintenance.text') }}</span>
  </div>
</template>

<script>
  import {EnvironmentService} from "@/environment";
  import {Button} from "@telenor/components-legacy/src/components";
  import FormWrapper from "@/components/FormWrapper";
  import Heading from "@/telenorComps/Heading/Heading";

  export default {
    name: "ActivateConfig",
    components: {
      FormWrapper,
      Heading
    },
    async mounted() {
      this.environment = await EnvironmentService.getEnvironment();
    },

    data() {
      return {
        environment: {},
        userCode: this.$route.query['userCode']
      }
    },
    methods: {
      emitFormState(isValid) {
        this.$emit('update:formValid', isValid);
      },
      validation(value){
        let isValid = true
        this.emitFormState(isValid);
        return isValid;
      },
    },
    computed: {
      disabledButton(){
      return !this.userCode || this.userCode.length === 0;
    },
    }
  }
</script>

<style lang="less" scoped>
 @import "../ibis-form-styles.less";
 @import "~@telenor/components-legacy/node_modules/@telenor/tokens/build/less/tokens.less";

 .button-icon {
   width: 18px;
   height: 18px;
   margin-bottom: -3px;
 }
 .iconbutton {
   background-image: url("../../../assets/telenor-logo-flat-white.svg");
   background-repeat: no-repeat;
   background-size: 18px 18px;
   background-position: 10% center;
 }

  .input__text {
    margin-bottom: @spacing-sm !important;
  }

  .code-label {
    margin: 0px auto;
    text-align: center;

    .input-wrap {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;

      ::v-deep .code-input {
        width: 150px;
        margin: 0px;
        padding: @spacing-md @spacing-lg;
        border-radius: 5px 0px 0px 5px;
        &:focus {
          //padding: calc(@spacing-md - 1px ) calc(@spacing-lg - 1px );
          border: 1px solid @color-telenor-link;
        }
      }

      .code-button {
        display: inline-block;
        width: 8px;
        height: 25px;
        padding: @spacing-md @spacing-lg;
        border-radius: 0px 5px 5px 0px;
        margin: 0px;
        background-color: @color-telenor-link;
        outline: none;
        border: none;
        position: relative;
        cursor: pointer;

        &:hover, &:focus {
          background-color: @color-telenor-link-hover;
        }
        &::before, &::after {
          content: "";
          display: block;
          border-top: 2px solid @color-white;
          position: absolute;
          top: 50%;
          left: 50%;
        }
        &::before {
          width: 10px;
          height: 10px;
          transform: translate(-30%, -50%) rotate(45deg);
          border-right: 2px solid @color-white;
        }
        &::after {
          width: 20px;
          transform: translate(-58%, -50%);
        }
      }
    }
  }

</style>

