<i18n>
  {
      "no": {
        "kyc": {
          "header": "Identifisering",
          "text": "For å fortsette bestillingen må du bekrefte din identitet. Velg et av alternativene:"
        },
        "login": {
          "header": "Velkommen",
          "text": "Velg din påloggingsleverandør"
        },
        "cancel": "Tilbake"
      },
      "en": {
        "kyc": {
          "header": "Identification",
          "text": "To continue you'll need to verify your identity. Please pick a verification method:"
        },
        "login": {
          "header": "Welcome",
          "text": "Select your login provider"
        },
        "cancel": "Go back"
    }
  }
</i18n>

<template>
  <div class="container">
    <form-wrapper>
      <heading size="l" class="heading">
        {{ loginContext === 'kyc' ? $t('kyc.header') : $t('login.header') }}
      </heading>
      <heading size="m" class="heading">
        {{ loginContext === 'kyc' ? $t('kyc.text') : $t('login.text') }}
      </heading>
      <idp v-for="idp in idps" :key="idp.displayName" :idp="idp" :url="getIdpUrl(idp)"/>
      <a class="link cancel" v-if="requestUrlEncoded" :href="'/account/cancel?requestUrlEncoded=' + requestUrlEncoded">{{$t('cancel')}}</a>
    </form-wrapper>
  </div>
</template>
<script>

  import {IdpService} from './idp.service';
  import FormWrapper from "@/components/FormWrapper";
  import Heading from "@/telenorComps/Heading/Heading";
  import Idp from "@/components/Idp";
  import {RequestParsingService} from "@/views/ibis/requestParsing.service";

  export default {
    name: "IbisLogin",
    components: {
      Idp,
      FormWrapper, Heading
    },
    data() {
      return {
        idps: [],
        loginContext: '',
        request: null,
        requestUrlEncoded: ''
      }
    },
    async mounted() {
      this.requestUrlEncoded = this.$route.query.requestUrlEncoded;
      this.request = RequestParsingService.parseUrlEncodedJson(this.requestUrlEncoded) || {};

      this.loginContext = this.request.loginContext === 'kyc' ? 'kyc' : 'default';
      let schemes = this.request.idps;
      this.idps = new IdpService().getIdps(schemes);
    },
    methods: {
      getIdpUrl(idp) {
        let idpRequest = Object.assign({}, this.request);
        idpRequest.authScheme = idp.authScheme;
        idpRequest.idps = null;

        if (!idpRequest.authorizeParams) {
          idpRequest.authorizeParams = {};
        }

        if (idp.amr) {
          idpRequest.authorizeParams.amr = idp.amr;
        }

        let encoded = RequestParsingService.toUrlEncodedJson(idpRequest);
        return `/External/Challenge?requestUrlEncoded=${encoded}`
      }
    }
  }
</script>
<style lang="less" scoped>
  @import "../ibis-form-styles.less";

  a.cancel {
    width: 100%;
    display: block;
    text-align: center;
    padding-top: 1em;
  }
</style>
