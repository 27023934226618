<template>
    <div class="debugger">
        <debugger-config :model="model" style="position: sticky; top: 0;"></debugger-config>
        <debugger-output></debugger-output>
    </div>
</template>

<script>
    import DebuggerConfig from "../../components/DebuggerConfig";
    import DebuggerOutput from "../../components/DebuggerOutput";

    export default {
        name: "Callback",
        components: {DebuggerOutput, DebuggerConfig},
        head(){
            return {
                title: "Debugger callback"
            }
        },
        computed: {
            model(){
                return this.$store.state.model;
            }
        }
    }
</script>

<style scoped>
.debugger{
    display: flex;
    align-items: flex-start;
}
</style>
