<i18n>
{
  "no": {
    "SEND_OTP_TO_ANOTHER_NUMBER": "Ønsker du å få koden til ett annet nummer? "
  },
  "en": {
    "SEND_OTP_TO_ANOTHER_NUMBER": "Receive one time code to different number? "
  }
}
</i18n>
<template>
  <div class="otp-alternatives"  v-if="phoneNumbers.length > 0">
    <a href="#" @click="toggleNumbers" class="link">
      {{ $t('SEND_OTP_TO_ANOTHER_NUMBER') }}
      <img src="../../../assets/chevron_right.svg" width="20" height="20" class="arrow-down"
           style="transform:rotate(90deg);"
           alt="arrow down"/>
    </a>
    <ul class="phone-dropdown">
      <div v-if="numberListOpen">

        <li v-for="number in phoneNumbers" :key="number.phoneNumberId">
          <div class="input-wrap">
            <Heading size="m" class="heading phone-numbers">
              {{ number.phoneNumberMasked }}
            </Heading>
            <Button type="button" size="extra-small" id="btnAuthorize" @click.native="numberClicked(number)">Send
            </Button>
          </div>
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
import Heading from "@/telenorComps/Heading/Heading";
import Button from "@/telenorComps/Button/Button";

export default {
  name: "OtpAlternatives",
  components: {Heading, Button},

  props: {
    phoneNumbers: {
      type: Array,
    },
  },

  data() {
    return {
      numberListOpen: false
    }
  },
  methods: {
    toggleNumbers() {
      this.numberListOpen = !this.numberListOpen;
    },

    numberClicked(number) {
      this.$emit("otpNumberClicked", number)
    },
  }

}
</script>

<style lang="less">
.input-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0.5rem;
  align-items: center;
}

.otp-alternatives {
  padding: 1.5rem 0.5rem;
}

.arrow-down {
  padding: .0rem 0.0rem 0.0rem 1rem;
}

</style>
