<i18n>
{
  "no": {
    "title": "Sett nytt passord",
    "wrongCode" : "Lenken er feil eller utgått",
    "passwordChanged" : "Passordet ble endret.",
    "newPassword": "Nytt passord",
    "mustBeEqual": "Må være likt som passordet",
    "setPassword": "Sett passordet"
  },
  "en": {
    "title": "Set a new password",
    "wrongCode" : "The link was wrong or has expired",
    "passwordChanged" : "The password was changed.",
    "newPassword": "New password",
    "mustBeEqual": "Passwords must be equal",
    "setPassword": "Set password"
  }
}
</i18n>
<template>
  <div class="container">
    <form-wrapper>
      <heading size="xl" class="heading">{{ $t('title') }}</heading>
      <ErrorMessage :error="error"/>
      <div class="text" v-html="text" v-if="text"></div>
      <div v-if="state==2" key="two">
        <p class="description">{{ $t('passwordChanged') }}</p>
      </div>
      <div v-if="state==-1" key="two">
        <p class="description">{{ $t('wrongCode') }}</p>
      </div>
      <div class="input-fields" v-if="state==1" key="one">
        <form @submit="submitPassword" method="post">
          <InputPassword name="password" :validation="validatePassword" :text="$t('newPassword')"
                         :placeholder="$t('newPassword')" :required=true v-model="password"/>
          <InputPassword name="passwordRepeat" :validation="validateRepeat" :text="$t('fields.passwordRepeat')"
                         :placeholder="$t('fields.passwordRepeat')" :required=true v-model="passwordRepeat"
                         :invalidError="$t('mustBeEqual')"
          />
          <div class="buttons">
            <t-button comp-type="submit-button" :value="$t('setPassword')" size="large" class="login-button"
                      :disabled="!valid"></t-button>
          </div>
          <PasswordRules/>
        </form>
      </div>
    </form-wrapper>
  </div>
</template>

<script>
  import {
      Button,
      Heading
  } from "@telenor/components-legacy/src/components";
  import FormWrapper from "@/components/FormWrapper";
  import { validateCode, setNewPassword } from "./api";
  import InputText from "@/telenorComps/Input/InputText";
  import InputPassword from "@/telenorComps/Input/InputPassword";
  import ErrorMessage from "@/views/elvis/components/errorMessage";
  import PasswordRules from "@/views/elvis/components/PasswordRules";

  const states = {
    BEGIN: 0,
    CODE_VALIDATED: 1,
    DONE: 2,
    WRONG_CODE: -1
  }

  export default {
    name: "ElvisSetNewPassword",
    components: {
      TButton: Button,
      FormWrapper,
      Heading,
      InputPassword,
      ErrorMessage,
      PasswordRules
    },
    head() {
      return {
        title: 'Sett nytt passord'
      }
    },
    computed: {
      valid() {
        return this.password == this.passwordRepeat && this.password && this.password.length > 6
      }
    },
    created() {
      validateCode(this.code, this.username).then(() => {
        this.state = states.CODE_VALIDATED;
      }).catch(e => {
        this.state = states.WRONG_CODE;
      });
    },
    data() {
      return {
        password: "",
        passwordRepeat: "",
        text: null,
        error: null,
        state: states.BEGIN,
        username: this.$route.query.username,
        code: this.$route.query.code
      }
    },
    methods: {
      submitPassword: function(e) {
        e.preventDefault();
        this.error = null;
        setNewPassword(this.username, this.code, this.password).then(() => {
          return this.state = states.DONE
        }).catch(err => {
         this.error = err;
        });
      },
      validatePassword: function(e) {
        this.password = e;
        return (this.password.length > 8)
      },
      validateRepeat: function(e) {
        this.passwordRepeat = e;
        return (this.passwordRepeat == this.password)
      }
    }
  }
</script>

