<i18n>
{
  "no": {
    "ACCOUNT_MISSING_EMAIL": "Kontoen mangler epostadresse",
    "ACCOUNT_MISSING_MOBILE": "Kontoen mangler mobilnummer",
    "TITLE": "Glemt brukernavn",
    "SEND_IT": "Send meg brukernavnet",
    "SUCCESS": "Brukernavnet er sent til kontaktinformasjonen du oppga. Gå tilbake til",
    "LOGIN": "innlogging",
    "links": {
      "forgotPassword": "Glemt passord",
      "forgotUsername": "Glemt brukernavn",
      "register": "Registrer ny bruker"
    },
    "otpFromSms": "Bekreftelseskode fra SMS",
    "otpSentToNumber": "Vi sendte en bekreftelseskode til ",
    "validate": "Valider kode"
  },
  "en": {
    "ACCOUNT_MISSING_EMAIL": "User account has no email address",
    "ACCOUNT_MISSING_MOBILE": "User account has no mobile number",
    "TITLE": "Forgot user name",
    "SEND_IT": "Send me the username",
    "SUCCESS": "The user name was sent. Go back to ",
    "LOGIN": "login",
    "links": {
      "forgotPassword": "Forgot password",
      "forgotUsername": "Forgot username",
      "register": "Register new user"
    },
    "otpFromSms": "One time code from SMS",
    "otpSentToNumber": "We sent a verification code to ",
    "validate": "Validate code"
  }
}
</i18n>

<template>
  <div class="container">

    <form-wrapper>
      <heading size="xl" class="heading">{{ $t('generic.logIn') }}</heading>
      <ErrorMessage :error="error"/>
      <div class="input-fields" v-if="state == 0" key="zero">
        <form @submit="onSubmitUsernamePassword" method="post">
          <input-text name="username" id="username" :text="$t('fields.username')"
                      placeholder="oda@nordmann.no" type="text"
                      validate-on-blur :required=true v-model="model.username"/>
          <input-password name="password" id="password" :text="$t('fields.password')"
                          placeholder="*****" validate-on-blur
                          :required=true v-model="model.password"/>
          <div class="buttons">
            <t-button comp-type="submit-button" id="btnLogin" :value="$t('generic.logIn')"
                      size="large" class="login-button"/>
          </div>
        </form>
        <div>
          <div>
            <router-link class="link" id="linkForgotUsername"
                         :to="{ name: 'elv-forgot-username', query: { request: request }}">
              {{ $t('links.forgotUsername') }}
            </router-link>
          </div>
          <div>
            <router-link class="link" id="linkForgotPassword"
                         :to="{ name: 'elv-forgot-password', query: { request: request }}">
              {{ $t('links.forgotPassword') }}
            </router-link>
          </div>
          <div>
            <router-link class="link" id="linkRegister"
                         :to="{ name: 'elv-register', query: { request: request }}">
              {{ $t('links.register') }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="input-fields" v-if="state == 1" key="one">
        <form @submit="onSubmitOtp" method="post">
          <heading v-if="otpSentToPhoneNumber" size="m" class="heading">
            {{ $t('otpSentToNumber') + otpSentToPhoneNumber }}
          </heading>
          <OtpAlternatives :phone-numbers="otpPhoneNumberAlternatives" @otpNumberClicked="sendNewOtp"/>

          <input-text name="otp" id="otpSMS" :text="$t('otpFromSms')" placeholder="******"
                      validate-on-blur :required=true
                      v-model="model.otp"
                      autocomplete="one-time-code"
                      pattern="[0-9]*"
                      inputmode="numeric"
          />
          <div>
            <t-button comp-type="submit-button" id="btnOtp" :value="$t('validate')" size="large"
                      class="login-button"></t-button>
          </div>
        </form>
      </div>
    </form-wrapper>
  </div>
</template>

<script>
import {Button} from "@telenor/components-legacy/src/components";
import InputText from "@/telenorComps/Input/InputText"
import InputPassword from "@/telenorComps/Input/InputPassword"
import FormWrapper from "@/components/FormWrapper";
import {login, LoginAction} from "@/views/elvis/api";
import ErrorMessage from "@/views/elvis/components/errorMessage";
import OtpAlternatives from "@/views/elvis/components/otpalternatives";
import Heading from "@/telenorComps/Heading/Heading";

const states = {
  BEGIN: 0,
  OTP: 1
}

export default {
  name: "ElvisLogin",
  components: {
    OtpAlternatives,
    TButton: Button,
    Heading,
    InputText,
    FormWrapper,
    InputPassword,
    ErrorMessage
  },
  head() {
    return {
      title: 'Logg inn'
    }
  },
  data() {
    return {
      state: states.BEGIN,
      error: "",
      otpSentToPhoneNumber: null,
      otpPhoneNumberAlternatives: null,
      model: {
        username: "",
        password: "",
        otp: null
      }
    };
  },
  mounted() {
  },
  methods: {
    onSubmitUsernamePassword: function (e) {
      this.error = null;
      e.preventDefault();
      login(this.model.username, this.model.password, null, this.request, null).then(result => {
        if (result.nextStep === LoginAction.REDIRECT) {
          window.location.href = result.redirect;
          return;
        }
        this.otpSentToPhoneNumber = result.otpSentToPhoneNumber
        this.otpPhoneNumberAlternatives = result.otpPhoneNumberAlternatives
        this.model.otp = "";
        this.state = states.OTP
       }).catch(reason => {
        this.error = reason
      });
    },
    sendNewOtp:function (number){
      this.error = null;
      login(this.model.username, this.model.password, null, this.request, number.phoneNumberId).then(result => {
        if (result.nextStep === LoginAction.REDIRECT) {
          window.location.href = result.redirect;
          return;
        }
        this.otpSentToPhoneNumber = result.otpSentToPhoneNumber
        this.otpPhoneNumberAlternatives = result.otpPhoneNumberAlternatives
        this.model.otp = "";
        this.state = states.OTP
      }).catch(reason => {
        this.error = reason
      });
    },

    onSubmitOtp: function (e) {
      this.error = null;
      e.preventDefault();
      login(this.model.username, this.model.password, this.model.otp, this.request).then(result => {
        if (result.nextStep === LoginAction.REDIRECT) {
          window.location.href = result.redirect;
          return;
        }
      }).catch(reason => {
        this.error = reason
      });
    },
    listenOtp() {
      if ('OTPCredential' in window) {
        const input = document.querySelector('input[autocomplete="one-time-code"]');
        if (!input) return;
        const ac = new AbortController();
        const form = input.closest('form');
        if (form) {
          form.addEventListener('submit', e => {
            ac.abort();
          });
        }

        navigator.credentials.get({
          otp: {transport: ['sms']},
          signal: ac.signal
        }).then(otp => {
          input.value = otp.code;
          if (form) form.submit();
        }).catch(err => {
          console.log(err);
        });
      }
    },
  },
  computed: {
    request() {
      return this.$route.query['request'] || "";
    }
  }
}
</script>

