<template>
  <div class="error" v-if="errorMessage">
    {{ errorMessage }}
  </div>
</template>
<script>

export default {
  name: "ErrorMessage",
  props: ["error"],

  computed: {
    errorMessage() {
      if (!this.error) return null;
      if (this.error.type) {
        let key = this.error.type;
        if (this.error.subtype) {
          key = this.error.type + "." + this.error.subtype;
        }
        let val = this.$t(key);
        if (val !== key && typeof(val) === "string") return val;
        return this.error.message;
      }
      return this.error.message;
    }
  },
}
</script>

<style scoped>
.error {
  color: red;
  padding: 0.5em 0em;
}
</style>
