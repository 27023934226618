<i18n>
{
  "no":{
    "errorHeading": "En feil har oppstått ved innlogging",
    "errorDescription": "Siden fungerer ikke uten informasjonskapsler/cookies. \n Du må tillate informasjonskapsler i din nettleser og laste siden på nytt",
    "cookieDetails": "Les mer om informasjonskapsler",
    "errorDetails": "Detaljer"
  },
  "en":{
    "errorHeading": "Oh no! We weren't able to log you in",
    "errorDescription": "This page does not work without cookies. \n Enable cookies in your browser and reload the page ",
    "cookieDetails": " ",
    "errorDetails": "Details"
  }
}
</i18n>
<template>
<div class="container">
<section class="info-screen">
  <heading size="m">{{$i18n.t("errorHeading")}}</heading>
  <div class="error">
      <span style="white-space: pre-line">{{ $i18n.t("errorDescription") }}</span>
      <div class="bottom-link" v-if="showCookieDetails"> 
        <a href="https://www.telenor.no/om/personvern/informasjonskapsler/" class="link">{{ $i18n.t("cookieDetails") }}</a>
      </div>
  </div>
</section>
<br />
</div>
</template>

<script>
import Heading from "@/telenorComps/Heading/Heading";
import VueRouter from 'vue-router';

export default {
  name: "CorrelationFailed",
  components: {Heading},
  computed: {
    showCookieDetails() {
      var cookieDetails = this.$i18n.t('cookieDetails');
      if(typeof cookieDetails !== 'undefined' && cookieDetails) {
        return true;
      }
      return false;
    }
  }
}
</script>

<style lang="less" scoped>
 .detailedCorrelationFailed {
   font-size: 12px;
 }
</style>
