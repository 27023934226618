<template>
  <div class="form-wrapper">
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormWrapper",
  props: ['width', 'hide-logo'],
  computed: {}
}
</script>

<style lang="less" scoped>
.form-wrapper {
  max-width: 100%;
  display: flex;
  justify-content: center;
  background: #fff;
}
.content {
  width: 100%;
}

</style>
