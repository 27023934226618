<template>
  <input-base
          type="text"
          v-bind="$props"
          @input="$emit('input', $event)"
  ></input-base>
</template>

<script>
import InputBase from "./InputBase";
import commonProps from "./commonProps";

export default {
  name: "InputText",
  components: {InputBase},
  props: commonProps
};
</script>
