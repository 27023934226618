<i18n>
{
  "no": {
    "errors": {
      "badInputName": "Minst én bokstav",
      "badNin": "11 siffer påkrevd"
    },
    "firstAndMiddleName": "For- og mellomnavn",
    "yourFirstAndMiddleName": "Ditt for- og mellomavn",
    "lastName": "Etternavn",
    "yourLastName": "Ditt etternavn",
    "nationalIdentityNumber": "Fødselsnummer",
    "11digitNumber": "Ditt fødselsnummer (11 siffer)",
    "why": "Hvorfor?"
  },
  "en": {
    "errors": {
      "badInputName": "At least one character",
      "badNin": "11 digits"
    },
    "firstAndMiddleName": "First and middle name",
    "yourFirstAndMiddleName": "Your first and middle names",
    "lastName": "Last name",
    "yourLastName": "Your last name",
    "nationalIdentityNumber": "National ID number",
    "11digitNumber": "Your national ID number (11 digits)",
    "why": "Why?"
  }
}
</i18n>

<template>
  <div class="validate-number-form">
    <input type="hidden" placeholder="" class="" name="msisdn"
           v-model="signUpRequest.msisdn">
    <InputText :text="$t('firstAndMiddleName')"
               :placeholder="$t('yourFirstAndMiddleName')"
               class=""
               id="txtFirstAndMiddleName"
               name="firstAndMiddleName"
               v-model="signUpRequest.firstAndMiddleName"
               :validation="validateName"
               :invalid-error="$t('errors.badInputName')"
               :required="!signUpRequest.canCancelSignUp"
               :emptyError="$t('errors.badInputName')"
    />
    <InputText :text="$t('lastName')"
               :placeholder="$t('yourLastName')"
               class=""
               name="lastName"
               id="txtLastName"
               v-model="signUpRequest.lastName"
               :validation="validateName"
               :invalid-error="$t('errors.badInputName')"
               :required="!signUpRequest.canCancelSignUp"
               :emptyError="$t('errors.badInputName')"
    />
    <InputText :text="$t('nationalIdentityNumber')"
               :label-link="{
                  to: {name: 'ibis-national-id-info'},
                  text: $t('why')
               }"
               :placeholder="$t('11digitNumber')" class=""
               v-model="signUpRequest.nationalIdentityNumber"
               name="nationalIdentityNumber"
               id="txtNationalIdentityNumber"
               :validation="validateID"
               :validate-on-blur="!signUpRequest.canCancelSignUp"
               :invalid-error="$t('errors.badNin')"
               :required="!signUpRequest.canCancelSignUp"
               :emptyError="$t('errors.badNin')"
               autocomplete="off"
               inputmode="numeric"
    />
    <input type="hidden" name="signUpId" v-model="signUpRequest.signUpId">
  </div>
</template>

<script>
import InputText from "../../../telenorComps/Input/InputText";

export default {
  name: "ValidatePersonForm",
  data() {
    return {
      nameValid: false,
      ninValid: false
    }
  },
  components: {
    InputText
  },
  props: {
    value: String,
    signUpRequest: Object
  },
  methods: {
    emitFormState() {
      this.$emit('update:formValid', this.nameValid && this.ninValid);
    },
    validateName(value) {
      this.nameValid = value && value.length >= 1
      
      if(!value && this.signUpRequest.canCancelSignUp) {
        this.nameValid = true;
      }

      this.emitFormState();

      return this.nameValid;
    },
    validateID(value) {
      let pattern = /^\d{11}$/;
      if (value) {
        this.ninValid = pattern.test(value)
      }

      if(!value && this.signUpRequest.canCancelSignUp) {
        this.ninValid = true;
      }
      
      this.emitFormState();

      return this.ninValid;
    }
  }
}
</script>

<style lang="less" scoped>
@import "../ibis-form-styles.less";
</style>

