<i18n>
{
"no": {
"areYouSure": "Er du sikkker?",
"info": "Hvis du avbryter nå, vil du bli bedt om å bekrefte identiteten din ved neste innlogging til denne tjenesten.",
"info2": "Fordi du allerede har en Telenor ID, kan du fortsatt bruke den til å logge inn til andre tjenester som ikke krever bekreftelse av din identitet ved fødselsnummer.",
"yesIWantToCancel": "Ja, jeg vil avbryte",
"back": "Tilbake"
},
"en": {
"areYouSure": "Are you sure?",
"info": "If you cancel now, you will be asked to confirm your identity the next time you log into this service.",
"info2": "Since you already have a Telenor ID, you can still use it to log in to other services that do not require proof of identity with your national ID number.",
"yesIWantToCancel": "Yes, I want to cancel",
"back": "Back"
}
}
</i18n>
<template>
  <section class="info-screen">
    <heading size="l">{{$t("areYouSure")}}</heading>
    <p>{{$t("info")}}</p>
    <p class="small-text">{{$t("info2")}}</p>
      <a class="button confirm-cancel" href="/account/logout" target="_blank">
        {{ $t('yesIWantToCancel') }}
      </a>
    <a class="link" @click="$router.back()">
      <img class="chevron-left" src="@/assets/chevron_left.svg" alt="chevron left">
      {{$t("back")}}
    </a>
    <img class="logo" src="@/assets/telenor-logo-flat-blue.svg" alt="telenor logo blue"/>
  </section>
</template>
<script>
import {Heading} from "@telenor/components-legacy/src/components";
export default {
  name:"CancelWarning",
  components: {Heading}
}
</script>

<style lang="less" scoped>
  @import "./ibis-form-styles.less";

  .link {
    display: inline-block;
    margin-top: @spacing-lg;
  }
</style>
