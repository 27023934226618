import {Idp} from "@/types";

export class IdpService {
  private fallbackLogo: string = "idp_generic.svg";

  private idps: { [key: string]: Idp[] } = {
    "no.bankid.vipps": [
      new Idp(
        "no.bankid.vipps",
        "BankID",
        "BankID_Symbol.svg",
        "BID")
    ],
    "no.bankid.login.vipps": [
      new Idp(
        "no.bankid.login.vipps",
        "BankID",
        "BankID_Symbol.svg",
        "BID")
    ],
  }

  public getIdps(authSchemes: string[] | null): Idp[] {
    let filtered: Idp[] = new Array<Idp>();

    if (!authSchemes || authSchemes.length < 1) {
      return filtered;
    }

    authSchemes.forEach(scheme => {
      let idps = this.idps[scheme];
      if (idps) {
        filtered = filtered.concat(idps);
      } else {
        filtered.push(new Idp(scheme, scheme, this.fallbackLogo, null));
      }
    });
    return filtered;
  }
}
