<i18n>
{
  "no": {
    "verificationCode": "Bekreftelseskode (6 siffer)"
  },
  "en": {
    "verificationCode": "Verification code (6 digits)"
  }
}
</i18n>

<template>
  <div class="verify-person-form">
    <label class="input code-label">
      <p class="input__text">{{$t('verificationCode')}}</p>
      <div class="input-wrap">
        <input type="text"
               maxlength="6"
               placeholder=""
               class="code-input"
               name="code"
               v-model="verificationCode.code"
               autocomplete="one-time-code"
               pattern="[0-9]*"
               inputmode="numeric"
        />
        <button type="submit" class="code-button"/>
      </div>
    </label>
  </div>
</template>

<script>

  export default {
    name: "VerificationCodeForm",
    components: {},
    props: {
      verificationCode: Object
    },
  }
</script>

<style lang="less" scoped>
  @import "../ibis-form-styles.less";

  .input__text {
    margin-bottom: @spacing-sm !important;
  }

  .code-label {
    margin: 0px auto;
    text-align: center;

    .input-wrap {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;

      ::v-deep .code-input {
        width: 150px;
        margin: 0px;
        padding: @spacing-md @spacing-lg;
        border-radius: 5px 0px 0px 5px;
        &:focus {
          //padding: calc(@spacing-md - 1px ) calc(@spacing-lg - 1px );
          border: 1px solid @color-telenor-link;
        }
      }

      .code-button {
        display: inline-block;
        width: 8px;
        height: 25px;
        padding: @spacing-md @spacing-lg;
        border-radius: 0px 5px 5px 0px;
        margin: 0px;
        background-color: @color-telenor-link;
        outline: none;
        border: none;
        position: relative;
        cursor: pointer;

        &:hover, &:focus {
          background-color: @color-telenor-link-hover;
        }
        &::before, &::after {
          content: "";
          display: block;
          border-top: 2px solid @color-white;
          position: absolute;
          top: 50%;
          left: 50%;
        }
        &::before {
          width: 10px;
          height: 10px;
          transform: translate(-30%, -50%) rotate(45deg);
          border-right: 2px solid @color-white;
        }
        &::after {
          width: 20px;
          transform: translate(-58%, -50%);
        }
      }
    }
  }
</style>

