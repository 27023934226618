<template>
    <form-wrapper width="600" :hide-logo=true>
        <div v-if="user" id="debuggerOutput">
            <json-viewer
                    :value="{userinfo: user.profile,
                    jwt_access_token: parseJwt(user.access_token),
                    jwt_id_token: parseJwt(user.id_token)}"
                    :expand-depth="5"
            ></json-viewer>
          <json-viewer
            :value="{access_token: user.access_token}"
            :expand-depth="1"
            :preview-mode="true"
            :copyable="true"
          ></json-viewer>
        </div>
    </form-wrapper>
</template>

<script>
    import FormWrapper from "./FormWrapper";
    import {UserManager, WebStorageStateStore} from 'oidc-client';
    import JsonViewer from 'vue-json-viewer/ssr.js';
    import 'vue-json-viewer/style.css'

    export default {
        name: "DebuggerOutput",
        components: {
            FormWrapper, JsonViewer
        },
        data(){
            return {
                user:null
            }
        },
        methods: {
            getUserManager() {
                return new UserManager({
                    response_mode: 'query',
                    loadUserInfo: true,
                    userStore: new WebStorageStateStore({store: window.localStorage})
                });
            },
            parseJwt(jwt) {
                let base64Url = jwt.split('.')[1];
                let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                return JSON.parse(jsonPayload);
            }
        },
        async mounted() {
            let storedAuthority = localStorage.getItem('authority');
            if (storedAuthority)
                this.environment = storedAuthority;

            if (this.$route.query.state && this.$route.query.code) { // Callback
                let mgr = this.getUserManager();
                await mgr.signinRedirectCallback().then(user => {
                    this.user = user;
                    window.console.log(user);
                }).catch(err => {
                    window.console.error(err);
                });

            } else {
                window.console.error("Missing state param/code in return url?!");
            }
        }
    }
</script>

<style lang="less" scoped>
    .heading {
        margin: 50px auto;
        display: block;
        text-align: center;
    }

    .go-button {
        margin: 20px auto 0px;
        display: block;
    }
</style>
