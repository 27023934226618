<template>
  <component :is="type" class="heading" :class="classList">
    <slot>{{ text }}</slot>
  </component>
</template>
<script>
export default {
  name: "HeadingComponent",
  props: {
    type: {
      type: String,
      default: "h1"
    },
    text: {
      type: String,
      default: "Heading h1"
    },
    color: {
      type: String,
      default: null,
      validator: value => {
        return ["blue", "default"].includes(value);
      }
    },
    size: {
      type: String,
      default: null,
      validator: value => {
        return ["xs", "s", "m", "l", "xl", "xxl"].includes(value);
      }
    },
    fontWeight: {
      type: String,
      default: "500"
    }
  },
  computed: {
    classList() {
      let classList = [];
      let color = this.color == "blue" ? "heading--" + this.color : null;
      let size = this.size ? "heading--" + this.size : null;
      classList.push(color, size);
      return classList.length ? classList : [];
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@telenor/components-legacy/node_modules/@telenor/tokens/build/less/tokens.less";

.heading {

  &--blue {
    color: @color-base-telenor-link;
  }

  &--xs {
    font-size: @size-font-mobile-xs !important;
    @media screen and (min-width: @size-screen-tablet) {
      font-size: @size-font-desktop-xs !important;
    }
  }
  &--s {
    font-size: @size-font-mobile-small !important;
    @media screen and (min-width: @size-screen-tablet) {
      font-size: @size-font-desktop-small !important;
    }
  }
  &--m {
    font-size: @size-font-mobile-medium !important;
    font-weight: 500;
    @media screen and (min-width: @size-screen-tablet) {
      font-size: @size-font-desktop-medium !important;
    }
  }
  &--l {
    font-size: @size-font-mobile-large !important;
    @media screen and (min-width: @size-screen-tablet) {
      font-size: @size-font-desktop-large !important;
    }
  }
  &--xl {
    font-size: @size-font-mobile-xl !important;
    @media screen and (min-width: @size-screen-tablet) {
      font-size: @size-font-desktop-xl !important;
    }
  }
  &--xxl {
    font-size: @size-font-mobile-xxl !important;
    @media screen and (min-width: @size-screen-tablet) {
      font-size: @size-font-desktop-xxl !important;
    }
  }
}

h1.heading {
  font-size: @size-font-mobile-xxl;
  @media screen and (min-width: @size-screen-tablet) {
    font-size: @size-font-desktop-xxl;
  }
}

h2.heading {
  font-size: @size-font-mobile-xl;
  @media screen and (min-width: @size-screen-tablet) {
    font-size: @size-font-desktop-xl;
  }
}

h3.heading {
  font-size: @size-font-mobile-large;
  @media screen and (min-width: @size-screen-tablet) {
    font-size: @size-font-desktop-large;
  }
}

h4.heading {
  font-size: @size-font-mobile-medium;
  @media screen and (min-width: @size-screen-tablet) {
    font-size: @size-font-desktop-medium;
  }
}
</style>
