<template>
    <input type="hidden" :value="value" :name="name">
</template>

<script>
    export default {
        name: "HiddenInput",
        props: ['value', 'name']
    }
</script>
