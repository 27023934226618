<i18n>
{
  "no": {
    "errors": {
      "expired": "Økten har utløpt. Lukk nettleser og forsøk igjen.",
      "badOtp": "Bekreftelseskode du har tastet inn er feil"
    },
    "verify": "Bekreft eierskap",
    "smsSentTo": "Vi sendte en bekreftelseskode til",
    "andOthers": "og {0} andre",
    "showLess": "Vis mindre",
    "oneTimeCode": "bekreftelseskode",
    "resendIn": "Du kan få tilsendt en ny kode om {0} sekund",
    "didNotGetCode": "Ikke mottatt koden?",
    "resend": "Send på nytt",
    "submit": "Bekreft"
  },
  "en": {
    "errors": {
      "expired": "This session has expired. Please close this browser window and try again.",
      "badOtp": "The verification code you entered was incorrect."
    },
    "verify": "Verify ownership",
    "smsSentTo": "We sent a verification code to",
    "andOthers": "and {0} other{1}",
    "showLess": "Show less",
    "oneTimeCode": "Code",
    "resendIn": "You can have a new code sent in {0} second(s)",
    "didNotGetCode": "Did not get the code?",
    "resend": "Resend",
    "submit": "Confirm"
  }
}
</i18n>

<template>
  <form-wrapper>
    <heading size="l" class="heading">
      {{$t('smsSentTo')}}
    </heading>

    <ul class="phone-dropdown">
      <li v-for="number in phoneNumbers" :key="number">
        <heading size="l" class="heading phone-numbers">
          {{number}}
        </heading>
      </li>
      <div v-if="numbersDropdownOpen">
        <li v-for="number in dropdownNumbers" :key="number">
          <heading size="l" class="heading phone-numbers">
            {{number}}
          </heading>
        </li>
      </div>
      <a v-if="dropdownNumbers.length" class="link" @click="toggleNumbers">{{ numbersListText }}</a>
    </ul>

    <div class="error" v-if="error">
      {{ $t(error) }}
    </div>

    <div class="input-fields">
      <form @submit="onSubmit">
        <verification-code-form :verification-code="verificationCode"/>
      </form>
    </div>

    <heading size="xs" class="heading">
      <i18n path="resendIn" tag="label" for="seconds" v-if="resendTimer > 0">
        {{resendTimer}}
      </i18n>
      <label v-if="resendTimer === 0">
        {{ $t('didNotGetCode')}}
        <a class="link" href="" v-on:click="resend">{{ $t('resend') }}</a>
      </label>
    </heading>

  </form-wrapper>
</template>
<script>
  import FormWrapper from "@/components/FormWrapper";
  import {Heading} from "@telenor/components-legacy/src/components";
  import {VerificationService} from "@/views/ibis/sign-up/verification.service";
  import VerificationCodeForm from "@/views/ibis/sign-up/VerificationCodeForm";

  export default {
    name: "VerifyUser",
    components: {
      VerificationCodeForm,
      FormWrapper,
      Heading
    },
    data() {
      return {
        error: null,
        verificationCode: {},
        resendTimer: 2, // todo: change to actual value,
        dropdownNumbers: [],
        numbersDropdownOpen: false
      }
    },
    computed: {
      numbersListText(){
        if(this.numbersDropdownOpen) return this.$t("showLess");
        let plural = this.dropdownNumbers.length === 1 ? "" : "s";
        return this.$t("andOthers", {0: this.dropdownNumbers.length, 1: plural});
      },
      phoneNumbers() {
        let phoneNumbers = this.verificationCode.sentTo || "";
        phoneNumbers = phoneNumbers.split(",");
        if(phoneNumbers.length > 3) {
          this.setDropdownNumbers(phoneNumbers);
        }
        return phoneNumbers;
      }
    },
    methods: {
      toggleNumbers(){
        this.numbersDropdownOpen = !this.numbersDropdownOpen;
      },
      setDropdownNumbers(list){
        this.dropdownNumbers = list.splice(3, list.length);
      },
      handleError(error) {
        this.error = error.i18nPath;
      },
      async resend(e) {
        e.preventDefault();

        await this.verificationService.resend(this.verificationId);
        this.resendTimer = 30;
      },
      async onSubmit(e) {
        e.preventDefault();
        this.error = null;

        let response = await this.verificationService.validate(
          this.verificationId, this.verificationCode.code
        );

        if (response && response.errorCode) {
          this.handleError(response);
        } else if (response.verificationCodeRequired === false && response.location) {
          window.location = response.location;
        }
      },
      listenOtp() {
        if ('OTPCredential' in window) {
          const input = document.querySelector('input[autocomplete="one-time-code"]');
          if (!input) return;
          const ac = new AbortController();
          const form = input.closest('form');
          if (form) {
            form.addEventListener('submit', e => {
              ac.abort();
            });
          }

          navigator.credentials.get({
            otp: { transport:['sms'] },
            signal: ac.signal
          }).then(otp => {
            input.value = otp.code;
            if (form) form.submit();
          }).catch(err => {
            console.log(err);
          });
        }
      }
    },
    watch: {
      resendTimer: {
        handler(value) {
          if (value > 0) {
            setTimeout(() => {
              this.resendTimer--;
            }, 1000);
          }
        },
        immediate: true // This ensures the watcher is triggered upon creation
      }
    },
    created() {
      this.verificationService = new VerificationService();
      this.verificationId = this.$route.query.codeId;
    },

    async mounted() {
      let verificationCode = await this.verificationService.get(this.verificationId);

      if (!verificationCode.errorCode) {
        this.verificationCode = verificationCode;
      } else {
        this.handleError(verificationCode);
      }
    }
  }
</script>
<style lang="less" scoped>
  @import "../ibis-form-styles.less";

  h1.heading {
    margin-bottom: 0px;
  }

  .phone-dropdown {
    text-align: center;

    a {
      display: inline-block;
      margin-top: @spacing-md;
      font-size: @font-size-desktop-xs;
    }
  }

  ::v-deep h1.heading.phone-numbers {
    margin: 0px auto;
  }
</style>
