// @ts-ignore
import base64 from 'base64-url';
import {RequestParameters} from "@/types";

export class RequestParsingService {
  public static parseUrlEncodedJson(queryParameter: string | any): RequestParameters | null {
    if (typeof queryParameter === 'string') {
      let requestDecoded = base64.decode(queryParameter);
      return JSON.parse(requestDecoded);
    }
    return null;
  }

  public static toUrlEncodedJson(data: object): string {
    let json = JSON.stringify(data);
    return base64.encode(json);
  }
}
