<i18n>
{
  "no": {
    "header": "Gratulerer, du er nå ferdig med å konfigurere enheten din!",
    "infoMessage": "Enheten din er nå tilkoblet. Du kan nå lukke dette vinduet."
  },
  "en": {
    "header": "Congratulations, you are now finished with setting up your device!",
    "infoMessage": "Your device is now connected. You may now close this window."
  }
}
</i18n>
<template>
  <div class="container">
    <form-wrapper>

    <div class="successImage"></div>
    
    <header size="l" class="heading"> {{ $t('header') }} </header>
    <div class="info-message" style="text-align:center"> {{ $t('infoMessage') }} </div>
    </form-wrapper>
    </div>
</template>

<script>
  import ModelSpecForm from "@/components/ModelSpecForm";
  import {RequestParsingService} from "@/views/ibis/requestParsing.service";
  import {ErrorService} from "@/error/error.service";
  import {DeviceService} from "./device-flow.service";
  import {UserManager, WebStorageStateStore} from 'oidc-client';
  import {EnvironmentService} from "@/environment";
  import {Button} from "@telenor/components-legacy/src/components";
  import FormWrapper from "@/components/FormWrapper";
  import Heading from "@/telenorComps/Heading/Heading";
  import InputText from "@/telenorComps/Input/InputText";
  import JsonViewer from 'vue-json-viewer/ssr.js';
  import UrlForm from "@/components/UrlForm";

  export default {
    name: "SuccessConfig",
    components: {
      FormWrapper,
    }
  }

</script>

<style lang="less" scoped>
 @import "../ibis-form-styles.less";
 @import "~@telenor/components-legacy/node_modules/@telenor/tokens/build/less/tokens.less";

 .button-icon {
   width: 18px;
   height: 18px;
   margin-bottom: -3px;
 }
 .iconbutton {
   background-image: url("../../../assets/telenor-logo-flat-white.svg");
   background-repeat: no-repeat;
   background-size: 18px 18px;
   background-position: 10% center;
 }

  .successImage {
   background-image: url("../../../assets/device_success.svg");
   width: 100px;
   height: 100px;
   background-repeat: no-repeat;
   margin-top: 60px;
   margin-left: 35%;
   position: relative;
 }

  .failureImage {
   background-image: url("../../../assets/device_failure.svg");
   width: 100px;
   height: 100px;
   background-repeat: no-repeat;
   margin-top: 60px;
   margin-left: 35%;
   position: relative;
 }

 .userText {
   color: darkgreen;
   font-size: 18px;
 }
</style>