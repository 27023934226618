import {SignUpRequest, ApiError, RedirectResponse, SetupSignUpRequest} from "@/types";
import axios from "axios";
import {ErrorService} from "@/error/error.service";

export class SignUpService {

  public async getSignUpRequest(requestId: string | null): Promise<SignUpRequest | ApiError> {
    let url = `/sign-up/${requestId}`;

    try {
      let signUpRequest = await axios.get<SignUpRequest>(url);
      return signUpRequest.data;

    } catch (e: any) {
      return ErrorService.getError("ibis", e.response.data.errorCode)
    }
  }

  public async getSetupForSignUp(requestId: string | null): Promise<SetupSignUpRequest | ApiError> {
    let url = `/sign-up/setup/${requestId}`;
    try {
      let signUpRequest = await axios.get<SetupSignUpRequest>(url);
      return signUpRequest.data;
    } catch (e: any) {
      if (e.response && e.response.data && e.response.data.errorCode) {
        return ErrorService.getError("ibis", e.response.data.errorCode)
      }

      if (e.response && e.response.data) {
        return ErrorService.getError("ibis", e.response.data)
      }

      return ErrorService.getGenericError();
    }
  }

  public async sendSignUpRequest(
    requestId: string, formData: string
  ): Promise<RedirectResponse | ApiError> {

    let url = `/sign-up/${requestId}/validate`;

    try {
      let response = await axios({
        method: 'POST',
        url: url,
        data: formData,
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
      });

      return response.data as RedirectResponse;
    } catch (e: any) {
      if (e.response && e.response.data) {
        return ErrorService.getError("ibis", e.response.data.errorCode)
      }

      return ErrorService.getGenericError();
    }
  }

  public async cancelSignUp(
    requestId: string) : Promise<RedirectResponse | ApiError> {
    let url = `/sign-up/${requestId}/signup`;

    try {
      let response = await axios({
        method: 'POST',
        url: url,
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
      });

      return response.data as RedirectResponse;
    } catch (e: any) {
      if (e.response && e.response.data) {
        return ErrorService.getError("ibis", e.response.data.errorCode)
      }

      return ErrorService.getGenericError();
    }
  }
}
