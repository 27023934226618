<template>
    <Login-config/>
</template>

<script>
    import LoginConfig from "./LoginConfig";

    export default {
        name: "DeviceLogin",
        head(){
            return {
                title: "DeviceLogin"
            }
        },
        components: {LoginConfig},
    }
</script>

