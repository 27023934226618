<template>
    <div class="text-block">
        {{value}}
    </div>
</template>

<script>
    export default {
        name: "TextBlock",
        props: ['value']
    }
</script>

<style scoped>
    .text-block{
        font-family: "Telenor", Helvetica, sans-serif;
        margin-bottom: 20px;
        margin-top: -15px;
        background: #f1f1f1;
        text-align: center;
        padding: 5px 0;
        border-radius: 7px;
        font-size: 14px;
    }
</style>