const baseUrl = '/api/elv';

export function forgotUsername(data: ForgottenUsername): Promise<void> {
	return fetch(`${baseUrl}/login/forgotten-username`, {
		method: 'POST',
	    headers: {
	      'Content-Type': 'application/json'
	    },
	    body: JSON.stringify(data)
	}).then(response => {
    if (response.ok) return Promise.resolve();
    return rejectWithError(response);

	});
}

export function forgotPassword(username: string): Promise<null> {
	return fetch(`${baseUrl}/login/send-reset`, {
		method: 'POST',
	    headers: {
	      'Content-Type': 'application/json'
	    },
	    body: JSON.stringify({ username: username })
	}).then(response => {
    if (response.ok) return null;
    return rejectWithError(response);

	});
}

export function validateCode(code: string, username: string): Promise<void> {
	return fetch(`${baseUrl}/login/check-reset-code?` + 'username=' + encodeURIComponent(username) + '&code=' + encodeURIComponent(code), {
		method: 'POST',
	    headers: {
	      //'Content-Type': 'application/json'
	      'Content-Type': 'application/x-www-form-urlencoded'
	    },
	    //body: JSON.stringify({username: username, code: code })
	    body: ""
	}).then(response => {
    if (response.ok) return Promise.resolve();
    return rejectWithError(response);

	});
}

export function beginRegistration(model: RegistrationModel) : Promise<void> {
  return fetch(`${baseUrl}/registration/initiate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(model)
  }).then(response => {
    if (response.ok) return Promise.resolve();
    return rejectWithError(response);
  });
}
export function finishRegistration(email: string, smsOtp: string, emailOtp: string) : Promise<void> {
  return fetch(`${baseUrl}/registration/finish`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email: email, smsOtp: smsOtp, emailOtp: emailOtp })
  }).then(response => {
    if (response.ok) return Promise.resolve();
    return rejectWithError(response);
  });
}


export function setNewPassword(username: string, code: string, password: string): Promise<void> {
	return fetch(`${baseUrl}/login/reset-password`, {
		method: 'POST',
	    headers: {
	      'Content-Type': 'application/json'
	    },
	    body: JSON.stringify({ username: username, code: code, password: password })
	}).then(response => {
    if (response.ok) return Promise.resolve();
    return rejectWithError(response);
	});
}

function rejectWithError(response: Response) : Promise<any>{
  return response.text().then(text => {
    if (!text) return Promise.reject({ "message": response.statusText });
    try {
      let error = JSON.parse(text);
      return Promise.reject(error);
    } catch(e) {
      return Promise.reject({ "message": text });
    }
  });
}

export function login(username: string, password: string, otp: string | null, request: string, numberForOtp: string | null) : Promise<LoginResult> {
	return fetch(`${baseUrl}/oauth/login`, {
	    redirect: 'manual',
		  method: 'POST',
	    headers: {
	      'Content-Type': 'application/x-www-form-urlencoded'
      },
	    body: "dontRedirect=true" +
        "&username=" + encodeURIComponent(username) +
        (otp ? "&otp=" + encodeURIComponent(otp) : "") +
        "&password=" + encodeURIComponent(password) +
        "&request=" + encodeURIComponent(request) +
        (numberForOtp ? "&numberIdForOtp=" + encodeURIComponent(numberForOtp):"")
	}).then(response => {
	  if (response.status == 202) {
      return response.json().then(json => {
        let otpPhoneNumberAlternatives = json.otpPhoneNumberAlternatives ? json.otpPhoneNumberAlternatives : [];
        return Promise.resolve(new LoginResult(LoginAction.OTP,  null, json.otpSentToPhoneNumber, otpPhoneNumberAlternatives))
      });
    }
	  if (response.ok) {
	    return response.json().then(json => {
	      return Promise.resolve(new LoginResult(LoginAction.REDIRECT, json.redirectUrl, null, []))
      });
    }
	  return rejectWithError(response)
  })
}



export enum LoginAction {
	PASSWORD,
	OTP,
	REDIRECT
}

export class LoginResult {
	nextStep: LoginAction;
	redirect: string | null;
  otpSentToPhoneNumber: string | null;
  otpPhoneNumberAlternatives: OtpPhoneNumber[];
	constructor(nextStep: LoginAction, redirect: string | null = null, otpSentToPhoneNumber: string | null = null, otpPhoneNumberAlternatives: OtpPhoneNumber[]) {
		this.nextStep = nextStep;
		this.redirect = redirect
		this.otpSentToPhoneNumber = otpSentToPhoneNumber
    this.otpPhoneNumberAlternatives = otpPhoneNumberAlternatives
	}
}
export interface OtpPhoneNumber{
  phoneNumberId: string,
  phoneNumberMasked: string
}
export interface ForgottenUsername {
	firstName: string,
	middleName: string | null,
	lastName: string,
	birthday: string,
	zipCode: string,
	email: string | null,
	phone: string | null
}

export interface ForgottenPasswordOptions {
	maskedPhone: string | null,
	maskedEmail: string | null
}

export interface RegistrationModel {
  phone: string,
  email: string,
  password: string
}
