var render, staticRenderFns
import script from "./LoginConfig.vue?vue&type=script&lang=js&"
export * from "./LoginConfig.vue?vue&type=script&lang=js&"
import style0 from "./LoginConfig.vue?vue&type=style&index=0&id=949ce9f4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "949ce9f4",
  null
  
)

/* custom blocks */
import block0 from "./LoginConfig.vue?vue&type=custom&index=0&blockType=div&class=info-message"
if (typeof block0 === 'function') block0(component)

export default component.exports