<i18n>
{
  "en": {
    "verificationCode": "Verification code (5 digits)",
    "kookaburra": {
      "login": {
        "otp": {
          "auto": {
            "lead": "Verification code is read. Please verify."
          },
          "sms": {
            "lead": "Verification code has been sent by text message to {0}."
          },
          "ivr": {
            "lead": "You will receive a call to {0}. A verification code will be read to you (5 digits)"
          }
        },
        "abort": "Abort"
      },
      "errors": {
        "wrongotp": "Wrong verification code.",
        "toomanyotpattempts": "There has been too many attempts.",
        "wrongformatotp": "Given verification code is not valid. Only numbers are allowed.",
        "otpnotfound": "Could not find the requested verification code.",
        "requestnotvalid": "Request is not valid",
        "missingcookie": "Missing cookie"
      }
    }
  },
  "no": {
    "verificationCode": "Bekreftelseskode (5 siffer)",
    "kookaburra": {
      "login": {
        "otp": {
          "auto": {
            "lead": "Bekreftelseskode er lest. Vennligst bekreft."
          },
          "sms": {
            "lead": "Bekreftelseskode sendt på SMS til {0}."
          },
          "ivr": {
            "lead": "Du blir oppringt på {0}. Bekreftelseskoden leses opp (5 siffer)."
          }
        },
        "abort": "Avbryt"
      },
      "errors": {
        "wrongotp": "Feil bekreftelseskode.",
        "toomanyotpattempts": "Du har prøvd for mange ganger.",
        "wrongformatotp": "Bekreftelseskoden har feil format. Kun tall er lov.",
        "otpnotfound": "Ingen gyldig sesjon",
        "requestnotvalid": "Sesjonen er ikke gyldig lenger",
        "missingcookie": "Mangler informasjonskapsel"
      }
    }
  }
}

</i18n>
<template>
  <form-wrapper>

    <heading size="l" class="heading">
      {{ $t('kookaburra.login.otp.' + ivrOrSms + '.lead', [phoneNumber]) }}
    </heading>

    <div class="error" v-if="error">
      {{ $t(error.message.i18nPath) }}
    </div>

    <div class="input-fields top-space">
      <form :action="loginUrl" method="post">

        <div class="verify-person-form">
          <label class="input code-label">
            <p class="input__text">{{$t('verificationCode')}}</p>
            <div class="input-wrap">
              <input type="text"
                     maxlength="5"
                     placeholder=""
                     class="code-input"
                     name="otp"
                     v-model="otp"
                     autocomplete="one-time-code"
                     pattern="[0-9]*"
                     inputmode="numeric"
              />
              <button type="submit" class="code-button" :disabled="disabledButton" />
            </div>
          </label>
        </div>

        <input type="hidden" name="ivr" :value="$route.query.ivr" v-if="$route.query.ivr"/>

      </form>
    </div>
  </form-wrapper>
</template>

<script>
import FormWrapper from "../../components/FormWrapper";
import {
  Heading
} from "@telenor/components-legacy/src/components";

import {ErrorService} from "@/error/error.service";

export default {
  name: "index.vue",
  components: {
    FormWrapper,
    Heading
  },
  data() {
    return {
      otp: this.$route.query.otp_from || ''
    }
  },
  mounted() {
    this.listenOtp();
  },
  methods: {
    listenOtp() {
      if ('OTPCredential' in window) {
        const input = document.querySelector('input[autocomplete="one-time-code"]');
        if (!input) return;
        const ac = new AbortController();
        const form = input.closest('form');
        if (form) {
          form.addEventListener('submit', e => {
            ac.abort();
          });
        }

        navigator.credentials.get({
          otp: { transport:['sms'] },
          signal: ac.signal
        }).then(otp => {
          input.value = otp.code;
          if (form) form.submit();
        }).catch(err => {
          console.log(err);
        });
      }
    }
  },
  computed: {
    disabledButton(){
      return !this.otp || this.otp.length === 0;
    },
    ivrOrSms() {
      if(!!this.$route.query.otp_from){
        return "auto"
      }
      return this.$route.query.ivr === "true" ? "ivr" : "sms"
    },
    loginUrl() {
      return process.env.VUE_APP_KOOKABURRA_BASE_URL + '/oauth/login';
    },
    phoneNumber() {
      return this.$route.query.phoneNumber
    },
    error() {
      let err = this.$route.query.error
      if (err) {
        let msg = ErrorService.getError("kookaburra", err)
        return {message: msg, code: err}
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import (reference) "~@telenor/tokens/build/less/variables.less";
@import "../ibis/ibis-form-styles.less";

::v-deep .input-fields {
  min-width: 400px;

  input[type=submit] {
    margin: 20px auto 0;
    display: block;
  }
}

.heading {
  margin: 50px auto;
  display: block;
  text-align: center;
}

@media screen and (max-width: 768px) {
  ::v-deep .input-fields {
    min-width: 0;
    width: 100%;

    form {
      label + label {
        margin-top: 30px;
      }
    }
  }
}

.top-space {
  margin-top: 20px;
}

.text {
  margin-bottom: 50px;
  font-family: "Source Sans Pro", sans-serif;
}

.error {
  padding: 10px 15px;
  font-family: @asset-font-telenor-name;
  font-size: 14px;
  background: @color-information-critical-normal;
  border-radius: 7px;
  margin-bottom: 50px;
  text-align: center;
}

.input__text {
  margin-bottom: @spacing-sm !important;
}

.code-label {
  margin: 0px auto;
  text-align: center;

  .input-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    ::v-deep .code-input {
      width: 150px;
      margin: 0px;
      padding: @spacing-md @spacing-lg;
      border-radius: 5px 0px 0px 5px;
      &:focus {
        //padding: calc(@spacing-md - 1px ) calc(@spacing-lg - 1px );
        border: 1px solid @color-telenor-link;
      }
    }

    .code-button {
      display: inline-block;
      width: 8px;
      height: 25px;
      padding: @spacing-md @spacing-lg;
      border-radius: 0px 5px 5px 0px;
      margin: 0px;
      background-color: @color-telenor-link;
      outline: none;
      border: none;
      position: relative;
      cursor: pointer;

      &:hover, &:focus {
        background-color: @color-telenor-link-hover;
      }
      &::before, &::after {
        content: "";
        display: block;
        border-top: 2px solid @color-white;
        position: absolute;
        top: 50%;
        left: 50%;
      }
      &::before {
        width: 10px;
        height: 10px;
        transform: translate(-30%, -50%) rotate(45deg);
        border-right: 2px solid @color-white;
      }
      &::after {
        width: 20px;
        transform: translate(-58%, -50%);
      }
    }
  }
}
</style>
