export class EnvironmentService {
  public static async getEnvironment() {
    let result = await fetch('/env', {method: 'GET'});

    if (result.ok) {
      return await result.json();
    }

    return {
      value: 'https://id-test.telenor.no',
      label: "STAGING",
      tnd: 'https://www.telenorid-staging.com/about',
      onBlank: "", 
      privacyUrl: "https://www.telenor.no/om/personvern", 
      useOldSignUpFlow: "true",
      ksUrlNo: "https://www.telenor.no/kundeservice/kontakt-oss/",
      ksUrlEn: "https://www.telenor.no/kundeservice/kontakt-oss/contact-customer-service/",
      maintenance: "false"
    }
  }
}
