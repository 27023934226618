import {StepUpRequest, ApiError} from "@/types";
import axios from "axios";
import {ErrorService} from "@/error/error.service";

export class StepUpService {

  public async getStepUpRequest(requestId: string | null): Promise<StepUpRequest | ApiError> {
    let url = `/step-up/${requestId}`;

    try {
      let stepUpRequest = await axios.get<StepUpRequest>(url);
      return stepUpRequest.data;

    } catch (e: any) {
      return ErrorService.getError("ibis", e.response.data.errorCode)
    }
  }
}
