<i18n>
{
  "no": {
    "CHOOSE_CONTACT": "Velg kontaktpunkt",
    "REQUEST_PW": "Be om nytt passord"
  },
  "en": {
    "CHOOSE_CONTACT": "Choose point of contact",
    "REQUEST_PW": "Request new password"
  }
}
</i18n>
<template>
	<form @submit="onSubmit" method="post">
		<input-text name="username" :text="$t('fields.username')" :placeholder="$t('fields.username')" :required=true
			validate-on-blur v-model="username"
		/>
    <div class="buttons">
		  <t-button  comp-type="submit-button" :value="$t('REQUEST_PW')" size="large" class="login-button"></t-button>
    </div>
	</form>
</template>

<script>
  import {
      Button
  } from "@telenor/components-legacy/src/components";
  import InputText from "@/telenorComps/Input/InputText";


  export default {
    name: "ElvisForgotPasswordForm",
    components: {
        TButton: Button,
        InputText
    },
    data() {
      return {
        username: null
      }
    },
    methods: {
      onSubmit: function(e) {
        e.preventDefault();
        this.$emit('formSubmitted', this.username);
      }
    }
  }
</script>

