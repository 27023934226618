<template>
  <component
    :is="type"
    :id="id"
    :compType="type"
    :type="inputSubmitReset"
    :href="href"
    :secondary="secondary"
    :class="classList"
    class="button"
    :disabled="disabled"
    @click="onClick"
  >
    <slot></slot>
    <span class="button__price" v-if="price">{{ priceValue }}</span>
    <span class="button__chevron" v-if="chevron">
      <chevronSvg/>
    </span>
  </component>
</template>

<script>
import chevronSvg from "@/assets/chevron_right.svg";

export default {
  name: "Button",
  components: {
    chevronSvg
  },
  props: {
    compType: {
    type: String,
    default: "button",
    validator: value => {
      return ["button", "vue", "a", "submit-button", "reset-button"].includes(
        value
      );
    }
  },
  id: {
    type: String,
    default: undefined
  },
  text: {
    type: String,
    default: "Klikk meg"
  },
  disabled: {
    type: Boolean,
    default: false
  },
  fluid: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: "medium",
    validator: value => {
      return ["extra-small", "small", "medium", "large"].includes(value);
    }
  },
  dynamic: {
    type: Boolean,
    default: false
  },
  chevron: {
    type: Boolean,
    default: false
  },
  price: {
    type: Number,
    default: null
  },
  secondary: {
    type: Boolean,
    default: false
  },
  href: {
    type: String,
    default: undefined
  }
}
,
computed: {
  type()
  {
    switch (this.compType) {
      case "submit-button":
        return "input";
      case "button":
      case "reset-button":
        return "button";
      case "vue":
        if (this.isNuxt) return "nuxt-link";
        else return "router-link";
      case "a":
        return "a";
      default:
        return "button";
    }
  }
,
  inputSubmitReset()
  {
    if (this.compType === "submit-button") return "submit";
    if (this.compType === "reset-button") return "reset";
    return null;
  }

,
  classList()
  {
    let classList = [];
    let fluid = this.fluid ? "button--fluid" : null;
    let size = "button--" + this.size;
    let staticWidth = this.dynamic ? null : size + "-static";
    let styleType = "button--" + (this.secondary ? "secondary" : "primary");
    let disabled = this.disabled ? "disabled" : null;
    let chevron = this.chevron ? "button--chevron" : null;
    classList.push(fluid, size, staticWidth, styleType, disabled, chevron);
    return classList.length ? classList : [];
  }
,
  chevronIcon()
  {
    return this.chevron ? chevronIcon : null;
  }
,
  priceValue()
  {
    return this.price ? this.price + ",-" : null;
  }
}
,
methods: {
  onClick()
  {
    console.log("Default on click")
    if (!this.inputSubmitReset) event.preventDefault();
    if (!this.disabled && (this.href !== undefined || "")) {
      if (this.type === "a") {
        window.location.href = this.href;
      }
      if (this.type === "nuxt-link" || this.type === "router-link")
        this.$router.push({path: this.href});
    }
  }
}
}
;
</script>


<style lang="less" scoped>
@import "~@telenor/components-legacy/node_modules/@telenor/tokens/build/less/tokens.less";

button,
.button {
  display: inline-block;
  border-radius: 4px;
  border: none;
  font-family: @asset-font-telenor-name;
  cursor: pointer;
  position: relative;
  padding: @spacing-m @spacing-xl; // Default mobile
  box-sizing: border-box;
  font-size: @size-font-desktop-xs;
  text-decoration: none;
  text-align: center;
  transition: background-color .3s ease-out;

  @media screen and (min-width: @size-screen-small) {
    padding: @spacing-s @spacing-xl; // Default desktop
  }

  & .button__chevron {
    display: inline-block;
    position: static;
    margin-left: @spacing-s;
  }

  &--fluid {
    width: 100% !important;
    text-align: center !important;
  }

  &--primary {
    background-color: @color-base-telenor-link;
    color: @color-base-white;

    &:hover {
      background-color: darken(@color-base-telenor-link, 15%);
    }

    & .button__chevron path {
      fill: @color-base-white;
    }
  }

  &--secondary {
    background-color: @color-base-white;
    color: @color-base-telenor-link;
    border: 1px solid @color-base-telenor-link;

    &:hover {
      background-color: @color-base-gray-pale;
    }

    & .button__chevron path {
      fill: @color-base-telenor-link;
    }
  }

  &--extra-small {
    padding: @spacing-s @spacing-xl; // Small for desktop
    font-size: @size-font-desktop-xs;

    &-static {
      width: 2 * @spacing-3-xl;
      text-align: center;
    }
  }
  &--small {
    padding: @spacing-m @spacing-xl; // Small on mobile = medium on desktop
    font-size: @size-font-desktop-small;

    &-static {
      width: 2 * @spacing-3-xl;
      text-align: center;
    }

    @media screen and (min-width: @size-screen-small) {
      padding: @spacing-s @spacing-xl; // Small for desktop
      &-static {
        width: 2 * @spacing-3-xl;
      }
    }
  }

  &--medium {
    padding: @spacing-m @spacing-xl;
    font-size: @size-font-desktop-small;

    &-static {
      width: 2 * @spacing-3-xl;
      text-align: center;
      overflow: hidden;
    }
  }

  &--large {
    padding: @spacing-l @spacing-xl;
    font-size: @size-font-desktop-small;

    &-static {
      width: 4 * @spacing-3-xl;

      & .button__chevron {
        position: absolute;
        text-align: left !important;
        right: @spacing-xl;
      }
    }
  }

  &--chevron {
    text-align: left;
  }

  &__price {
    position: absolute;
    right: @spacing-xxl;
  }
}

.disabled {
  background-color: @color-base-gray-light;
  color: @color-base-gray-dark;
  cursor: default;

  &.button--secondary {
    border: 1px solid @color-base-gray-light;
  }

  & .button__chevron path {
    fill: @color-base-gray-dark;
  }

  &:hover {
    background-color: @color-base-gray-light;
    color: @color-base-gray-dark;
  }
}
</style>
